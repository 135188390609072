import React, { FC } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import Block from '../Shared/Block';
import H2 from '../Shared/H2';
import Row from '../Shared/Row';
import Section from '../Shared/Section';
import StyledSlider from '../Shared/StyledSlider';

type Props = {
  stats?: any[];
};

const StatSlider: FC<Props> = ({ stats }) => {
  return (
    <Section>
      <Row>
        <Block>
          <StatSliderStyledSlider arrows={false} dots>
            {stats?.map((stat, index) => (
              <StyledSlider.Slide key={index}>
                <StatSliderH2>{stat.stat}</StatSliderH2>
              </StyledSlider.Slide>
            ))}
          </StatSliderStyledSlider>
        </Block>
      </Row>
    </Section>
  );
};

export default StatSlider;

const StatSliderStyledSlider = styled(StyledSlider.StyledSlider)`
  & .slick-dots {
    text-align: left;
  }
`;

const StatSliderH2 = styled(H2)`
  font-weight: ${variables.css.fontWeight.light};
  font-size: 2rem;
  line-height: 3rem;
  text-transform: none;
`;
