import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import useBlueMenuTrigger from '../../hooks/useBlueMenuTrigger';
import Block from '../Shared/Block';
import H2 from '../Shared/H2';
import Row from '../Shared/Row';
import Section from '../Shared/Section';

type Props = {
  text: string;
  isPaddingBottom: boolean | null;
};

const FullWidthHeading: FC<Props> = ({ text, isPaddingBottom }) => {
  const ref = useRef<HTMLDivElement>(null);

  const uniqueId = useBlueMenuTrigger(ref);

  return (
    <FullWidthHeadingSection
      id={uniqueId}
      ref={ref}
      isPaddingBottom={isPaddingBottom}
    >
      <Row>
        <Block>
          <FullWidthHeadingH2>{text}</FullWidthHeadingH2>
        </Block>
      </Row>
    </FullWidthHeadingSection>
  );
};

export default FullWidthHeading;

type FullWidthHeadingSectionProps = {
  isPaddingBottom: boolean | null;
};

const FullWidthHeadingSection = styled(Section)<FullWidthHeadingSectionProps>`
  background-color: ${variables.css.color.white};
  padding-bottom: ${(p) => (p.isPaddingBottom !== null ? '4rem' : 0)};

  ${variables.breakpoints.md} {
    padding-bottom: ${(p) => (p.isPaddingBottom !== null ? '6rem' : 0)};
  }

  ${variables.breakpoints.lg} {
    padding-bottom: ${(p) => (p.isPaddingBottom !== null ? '8rem' : 0)};
  }
`;

const FullWidthHeadingH2 = styled(H2)`
  color: ${variables.css.color.blue};
  margin-bottom: 0;
`;
