import { MutableRefObject, useContext, useLayoutEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import MenuContext from '../contexts/MenuContext';

export default function useBlueMenuTrigger(
  ref: MutableRefObject<HTMLDivElement>
) {
  const { addSectionInfo, removeSectionInfo } = useContext(MenuContext);

  // Create a unique ID to identify a section that should trigger the blue menu.
  const [uniqueId] = useState(Math.random().toString(16).slice(-5));

  useLayoutEffect(() => {
    // On mount, inform our MenuContext of this component's existence.

    const resizeObserver = new ResizeObserver(() => {
      // Whenever the size of the DOM element changes, update the MenuContext.
      const { id, offsetTop, offsetHeight } = ref.current;
      const top = offsetTop;
      const bottom = offsetTop + offsetHeight;

      removeSectionInfo(id);
      addSectionInfo(id, top, bottom);
    });

    resizeObserver.observe(ref.current);

    return () => {
      // On unmount, inform our MenuContext that this component is unmounted.

      resizeObserver.disconnect();

      const { id } = ref.current;
      removeSectionInfo(id);
    };
  }, []);

  // Pass the unique ID back to whichever component uses this custom hook.
  return uniqueId;
}
