import React, { FC, useRef } from 'react';
import styled from 'styled-components'
import { useTable, useSortBy, useFlexLayout } from 'react-table'
import Chevron from '../../assets/svg/chevron.svg';
import Block from '../Shared/Block';
import SectionHero from '../Shared/SectionHero';
import variables from '../../helpers/variables';
import RowHero from '../Shared/RowHero';
import useBlueMenuTrigger from '../../hooks/useBlueMenuTrigger';
var data = require("../../../static/top200.json");

type Props = {
  fields: string[]
};

const Top200Table: FC<Props> = ({ fields }) => {
  const ref = useRef<HTMLDivElement>(null);
  const uniqueId = useBlueMenuTrigger(ref);

  console.log('fields', fields);
  const columns = React.useMemo(
    () => {
    
      return [
        {
          Header: 'Pos',
          accessor: 'turnover_position',
          id: 'main_pos',
        },
        {
          Header: 'Company Name',
          accessor: 'company_name',
        },
        {
          Header: 'Top 200 Position',
          accessor: 'turnover_position',
        },
        {
          Header: 'Site Visibility',
          accessor: 'seo_visibility',
          Cell: props => new Intl.NumberFormat('en-GB').format(props.value)
        },
        {
          Header: 'Links',
          accessor: 'external_back_link',
          Cell: props => new Intl.NumberFormat('en-GB').format(props.value)
        },
        {
          Header: 'DA',
          accessor: 'da_score',
          Cell: props => new Intl.NumberFormat('en-GB').format(props.value)
        },
        {
          Header: 'PA',
          accessor: 'pa_score',
          Cell: props => new Intl.NumberFormat('en-GB').format(props.value)
        },
        {
          Header: 'Employees',
          accessor: 'no_of_employees',
          Cell: props => new Intl.NumberFormat('en-GB').format(props.value)
        },
        {
          Header: 'Turnover',
          accessor: 'turnover',
          Cell: props => new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(props.value)
        },
      ].filter(x => fields.includes(x.accessor));
    },
  [fields])

  return (
    <Top200Section id={uniqueId} ref={ref}>
      <RowHero>  
        <Block lgWidth={2 / 3} xlWidth={7 / 9}>
          <Styles>
            <Table columns={columns} data={data} />
          </Styles>
        </Block>
      </RowHero>
    </Top200Section>
  )
};

export default Top200Table;


function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout,
    useSortBy
  )

  return (
    <>
      
      <div {...getTableProps()} className="table">
        <div className="thead">
          {headerGroups.map(headerGroup => (
            <div
              {...headerGroup.getHeaderGroupProps({
                // style: { paddingRight: '15px' },
              })}
              className="tr"
            >
              {headerGroup.headers.map(column => (
                <div {...column.getHeaderProps(column.getSortByToggleProps())} className="th">
                  {column.render('Header')}
                   {/* Add a sort direction indicator */}
                   <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <ActiveChevronDown/>
                        : <ActiveChevronUp/>
                      : <InactiveChevron/>}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="tbody">
          {rows.map(row => {
            prepareRow(row)
            return (
              <div {...row.getRowProps()} className="tr">
                {row.cells.map(cell => {
                  return (
                    <div {...cell.getCellProps()} className="td">
                      {cell.render('Cell')}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

const Top200Section = styled(SectionHero)`
  background-color: ${variables.css.color.white};
  padding-top: 0;
`;

const InactiveChevron = styled(Chevron)`
  display: inline-block;
  opacity: 0.5;
  transform: rotate(180deg);
  margin-left: 0.325em;
`

const ActiveChevronDown = styled(InactiveChevron)`
  color: #FF00EB;
  opacity: 1.0;
  transform: rotate(0deg);
`

const ActiveChevronUp = styled(InactiveChevron)`
  color: #FF00EB;
  opacity: 1.0;
`

const Styles = styled.div`
padding: 1rem;
display: block;
overflow: hidden;
width: 100%;

.table {
  border-spacing: 0;
  overflow: auto;
  position: relative;

  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #DFDFDF;
    border-radius: 10px;
  }

  .thead {
    position: sticky;
    top: 0;
    z-index: 10;
    .td, .th {
      background: white;
      z-index: 10;
      border-bottom: 1px solid #979797;
      line-height: 1.1em;
    }
  }
  .tbody {
    max-height: 65vh;
  }
  .th {
    font-weight: bold;
    font-size: 1.3em;
  }
  .tr {
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
    :nth-child(2n) {
      .td {
        background-color: rgba(4,21,156,0.06);
      }
    }
  }
  .th,
  .td {
    margin: 0;
    padding: 0.5rem;
    position: relative;
    :last-child {
      border-right: 0;
    }
  }
}
`