import styled from 'styled-components';

const Video = styled.video`
  max-width: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
`;

export default Video;
