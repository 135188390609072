import React, { FC } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import useViewportWidth from '../../hooks/useViewportWidth';
import Block from '../Shared/Block';
import H2 from '../Shared/H2';
import Image from '../Shared/Image';
import ImageBackground from '../Shared/ImageBackground';
import Row from '../Shared/Row';
import Section from '../Shared/Section';

type Props = {
  slides?: any[];
  dots?: boolean;
  containImage?: boolean;
};

const ImageCarousel: FC<Props> = ({ slides, dots, containImage }) => {
  const { isViewportMobile } = useViewportWidth();

  function renderImage(slide) {
    if (isViewportMobile && slide.imageMobile) {
      return <Image image={slide.imageMobile} />;
    } else if (slide.image) {
      return <Image image={slide.image} />;
    } else {
      return null;
    }
  }

  return (
    <StyledSlider
      arrows={false}
      autoplay
      autoplaySpeed={5000}
      customPaging={(i) => <button data-page={`0${i + 1}`}>{i + 1}</button>}
      dots={dots}
      fade
      pauseOnHover={false}
    >
      {slides?.map((slide, index) => (
        <Slide key={index}>
          <ImageContainer isViewportMobile={isViewportMobile}>
            <ImageCarouselImageBackground containImage={containImage}>
              {renderImage(slide)}
              {slide.text && (
                <ImageCarouselSection>
                  <Row>
                    <Block mdWidth={1 / 2}>
                      <ImageCarouselH2>{slide.text}</ImageCarouselH2>
                    </Block>
                  </Row>
                </ImageCarouselSection>
              )}
            </ImageCarouselImageBackground>
          </ImageContainer>
        </Slide>
      ))}
    </StyledSlider>
  );
};

export default ImageCarousel;

const StyledSlider = styled(Slider)`
  line-height: 0;

  & .slick-dots {
    align-items: center;
    display: flex !important;
    height: 2.375rem;
    justify-content: space-between;
    margin-left: -1rem;
    margin-right: -1rem;
    position: absolute;
    left: 5%;
    right: 5%;
    top: 0;
    width: auto;

    ${variables.breakpoints.md} {
      margin-left: -1.25rem;
      margin-right: -1.25rem;
      left: 6.25%;
      right: 6.25%;
      top: 2rem;
    }

    ${variables.breakpoints.lg} {
      margin-left: -2rem;
      margin-right: -2rem;
      left: 15.625%;
      right: 15.625%;
    }

    & > li {
      display: block;
      flex-grow: 1;
      height: auto;
      margin: 0;
      padding: 1rem;
      width: auto;

      & > button {
        background-color: ${variables.css.color.white};
        height: 0.375rem;
        opacity: 0.5;
        padding: 0;
        position: relative;
        width: 100%;

        &::before {
          background-color: ${variables.css.color.pink};
          content: '';
          display: block;
          height: 0.375rem;
          position: absolute;
          left: 0;
          top: 0;
          transform: scaleX(0);
          transform-origin: left;
          transition-duration: 0s;
          transition-property: transform;
          transition-timing-function: linear;
          width: 100%;
          z-index: 10;
        }

        &::after {
          color: ${variables.css.color.white};
          content: attr(data-page);
          font-family: ${variables.css.fontFamily};
          font-size: ${variables.css.fontSize.md};
          font-weight: ${variables.css.fontWeight.black};
          opacity: 0.5;
          position: absolute;
          left: 0;
          top: 1.25rem;
        }
      }

      &.slick-active > button {
        opacity: 1;

        &::before {
          opacity: 1;
          transform: scaleX(1);
          transition-duration: 5s;
        }

        &::after {
          opacity: 1;
        }
      }
    }
  }

  &:hover {
  }
`;

const Slide = styled.div`
  outline: none !important;
  position: relative;
`;

type ImageContainerProps = {
  isViewportMobile: boolean;
};

const ImageContainer = styled.div<ImageContainerProps>`
  align-items: center;
  display: flex;
  height: 0;
  padding-bottom: ${(p) => (p.isViewportMobile ? '100%' : '50%')};
  width: 100%;
`;

const ImageCarouselImageBackground = styled(ImageBackground)`
  align-items: center;
  display: flex;
`;

const ImageCarouselSection = styled(Section)`
  width: 100%;
`;

const ImageCarouselH2 = styled(H2)`
  ${variables.breakpoints.xl} {
    font-size: ${variables.css.fontSize['6xl']};
  }
`;
