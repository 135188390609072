import styled from 'styled-components';
import variables from '../../helpers/variables';
import Wysiwyg from './Wysiwyg';

const FiftyFiftyWysiwygText = styled(Wysiwyg)`
  margin-bottom: 2.25rem;

  ${variables.breakpoints.lg} {
    margin-bottom: 3rem;
  }
`;

export default FiftyFiftyWysiwygText;
