import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import useBlueMenuTrigger from '../../hooks/useBlueMenuTrigger';
import Block from '../Shared/Block';
import Row from '../Shared/Row';
import Section from '../Shared/Section';
import Wysiwyg from '../Shared/Wysiwyg';

type Props = {
  text: string;
  isPaddingBottom: true | null;
};

const FullWidthText: FC<Props> = ({ text, isPaddingBottom }) => {
  const ref = useRef<HTMLDivElement>(null);

  const uniqueId = useBlueMenuTrigger(ref);

  return (
    <FullWidthTextSection
      id={uniqueId}
      ref={ref}
      isPaddingBottom={isPaddingBottom}
    >
      <Row>
        <Block>
          <Wysiwyg dangerouslySetInnerHTML={{ __html: text }} isColorBlack />
        </Block>
      </Row>
    </FullWidthTextSection>
  );
};

export default FullWidthText;

type FullWidthTextSectionProps = {
  isPaddingBottom: true | null;
};

const FullWidthTextSection = styled(Section)<FullWidthTextSectionProps>`
  background-color: ${variables.css.color.white};
  padding-bottom: ${(p) => (p.isPaddingBottom !== null ? '4rem' : 0)};

  ${variables.breakpoints.md} {
    padding-bottom: ${(p) => (p.isPaddingBottom !== null ? '6rem' : 0)};
  }

  ${variables.breakpoints.lg} {
    padding-bottom: ${(p) => (p.isPaddingBottom !== null ? '8rem' : 0)};
  }
`;
