import React, { FC, useState } from 'react';
import { FieldInputProps } from 'react-final-form';
import styled from 'styled-components';
import variables from '../../helpers/variables';

type Props = {
  input: FieldInputProps<any, HTMLElement>;
  meta?: any;
  id: string;
  label: string;
  type: string;
};

const Input: FC<Props> = ({ input, meta, id, label, type }) => {
  const [isLabelMinimized, setIsLabelMinimized] = useState(false);

  function onBlur(event: any) {
    input.onBlur(event);
    setIsLabelMinimized(input.value !== '');
  }

  function onFocus(event: any) {
    input.onFocus(event);
    setIsLabelMinimized(true);
  }

  const StyledInputJSXElement = (
    <StyledInput
      {...input}
      id={id}
      onBlur={onBlur}
      onFocus={onFocus}
      type={type}
      willShowError={meta?.error && meta?.touched}
    />
  );

  return type === 'hidden' ? (
    StyledInputJSXElement
  ) : (
    <Container>
      <Label
        htmlFor={id}
        isMinimized={isLabelMinimized}
        willShowError={meta?.error && meta?.touched}
      >
        {label}
      </Label>
      {StyledInputJSXElement}
    </Container>
  );
};

export default Input;

const Container = styled.div`
  margin-bottom: 1rem;
  padding-top: 1.5rem;
  position: relative;
`;

type LabelProps = {
  isMinimized: boolean;
  willShowError: boolean;
};

const Label = styled.label<LabelProps>`
  color: ${(p) =>
    p.willShowError ? variables.css.color.red : variables.css.color.white};
  opacity: ${(p) => (p.isMinimized ? '1' : '0.75')};
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0.25rem;
  transform: ${(p) =>
    p.isMinimized ? 'scale(0.75) translateY(0)' : 'scale(1) translateY(100%)'};
  transform-origin: top left;
  transition: opacity 0.2s, transform 0.2s;
`;

type StyledInputProps = {
  willShowError: boolean;
};

const StyledInput = styled.input<StyledInputProps>`
  background-color: transparent;
  border-bottom-color: ${(p) =>
    p.willShowError ? variables.css.color.red : variables.css.color.white};
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-radius: 0;
  color: ${variables.css.color.white};
  font-family: ${variables.css.fontFamily};
  font-weight: ${variables.css.fontWeight.light};
  height: 2rem;
  outline: none;
  width: 100%;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    caret-color: ${variables.css.color.white} !important;
    -webkit-text-fill-color: ${variables.css.color.white} !important;
    transition-delay: 9999s;
  }
`;
