import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import videoBubblesMp4 from '../../assets/video/Bubbles.mp4';
import videoBubblesWebm from '../../assets/video/Bubbles.webm';
import videoConsoleMp4 from '../../assets/video/Console.mp4';
import videoConsoleWebm from '../../assets/video/Console.webm';
import videoPlanetMp4 from '../../assets/video/Planet.mp4';
import videoPlanetWebm from '../../assets/video/Planet.webm';
import escapeSpecialCharacters from '../../helpers/escapeSpecialCharacters';
import variables from '../../helpers/variables';
import Block from '../Shared/Block';
import BlockButton from '../Shared/BlockButton';
import Button from '../Shared/Button';
import FiftyFiftyWysiwygText from '../Shared/FiftyFiftyWysiwygText';
import FiftyFiftyWyswiygTitle from '../Shared/FiftyFiftyWysiwygTitle';
import Image from '../Shared/Image';
import Row from '../Shared/Row';
import RowButtons from '../Shared/RowButtons';
import Section from '../Shared/Section';
import Subtitle from '../Shared/Subtitle';
import Video from '../Shared/Video';
import VideoMaskConsole from '../Shared/VideoMaskConsole';
import VideoMaskPlanet from '../Shared/VideoMaskPlanet';

type Props = {
  subtitle?: string;
  title?: string;
  text?: string;
  links?: any[];
  imagesOrVideo: string;
  images?: any[];
  video?: string;
  mediaPosition: string;
  mediaPositionMobile: string;
  spaceBottom: boolean;
};

const FiftyFifty: FC<Props> = ({
  subtitle,
  title,
  text,
  links,
  imagesOrVideo,
  images,
  video,
  mediaPosition,
  mediaPositionMobile,
  spaceBottom,
}) => {
  function renderImageOrVideo() {
    switch (imagesOrVideo) {
      case 'images':
        return (
          <ImageContainer isAnchoredLeft={mediaPosition === 'left'}>
            <Image image={images[0]} />
          </ImageContainer>
        );
      case 'video':
        switch (video) {
          case variables.videos.bubbles:
            return (
              <VideoContainer isAnchoredLeft={mediaPosition === 'left'}>
                <Video autoPlay loop muted playsInline>
                  <source src={videoBubblesWebm} type='video/webm' />
                  <source src={videoBubblesMp4} type='video/mp4' />
                </Video>
              </VideoContainer>
            );
          case variables.videos.console:
            return (
              <VideoContainer
                isAnchoredLeft={mediaPosition === 'left'}
                isSquare
              >
                <Video autoPlay loop muted playsInline>
                  <source src={videoConsoleWebm} type='video/webm' />
                  <source src={videoConsoleMp4} type='video/mp4' />
                </Video>
                <VideoMaskConsole />
              </VideoContainer>
            );
          case variables.videos.planet:
            return (
              <VideoContainer
                isAnchoredLeft={mediaPosition === 'left'}
                isSquare
              >
                <Video autoPlay loop muted playsInline>
                  <source src={videoPlanetWebm} type='video/webm' />
                  <source src={videoPlanetMp4} type='video/mp4' />
                </Video>
                <VideoMaskPlanet />
              </VideoContainer>
            );

          default:
            console.log('Unrecognised selection in video!');
            return null;
        }
      default:
        console.log('Unrecognised selection in imagesOrVideo!');
        return null;
    }
  }

  return (
    <FiftyFiftySection spaceBottom={spaceBottom}>
      <Row
        reverse={
          mediaPosition === 'right' &&
          (mediaPositionMobile === null || mediaPositionMobile === 'top')
        }
      >
        {(mediaPositionMobile === null || mediaPositionMobile === 'top') && (
          <FiftyFiftyBlockMedia
            mdWidth={1 / 2}
            isRelative={imagesOrVideo === 'images'}
          >
            {renderImageOrVideo()}
          </FiftyFiftyBlockMedia>
        )}
        <FiftyFiftyBlockText mdWidth={1 / 2}>
          {subtitle && (
            <Subtitle fontSize={variables.css.fontSize.md}>{subtitle}</Subtitle>
          )}
          {title && (
            <FiftyFiftyWyswiygTitle
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {text && (
            <FiftyFiftyWysiwygText dangerouslySetInnerHTML={{ __html: text }} />
          )}
          {links && (
            <RowButtons>
              {links?.map(
                (item, index) =>
                  item.link ? 
                    item.link.url.startsWith('mailto:') ? (
                      <BlockButton key={index}>
                        <Button as={'a'} href={item.link.url}>
                          {escapeSpecialCharacters(item.link.title)}
                        </Button>
                      </BlockButton>
                    ) :
                    (
                      <BlockButton key={index}>
                        <Button as={Link} to={item.link?.url}>
                          {escapeSpecialCharacters(item.link.title)}
                        </Button>
                      </BlockButton>
                    ) 
                    : null
              )}
            </RowButtons>
          )}
        </FiftyFiftyBlockText>
        {mediaPositionMobile === 'bottom' && (
          <FiftyFiftyBlockMedia
            mdWidth={1 / 2}
            isRelative={imagesOrVideo === 'images'}
          >
            {renderImageOrVideo()}
          </FiftyFiftyBlockMedia>
        )}
      </Row>
    </FiftyFiftySection>
  );
};

export default FiftyFifty;

type FiftyFiftySectionProps = {
  spaceBottom: boolean;
};

const FiftyFiftySection = styled(Section)<FiftyFiftySectionProps>`
  padding-bottom: ${(p) => (p.spaceBottom ? '8rem' : '2rem')};
`;

type FiftyFiftyBlockMediaProps = {
  isRelative: boolean;
};

const FiftyFiftyBlockMedia = styled(Block)<FiftyFiftyBlockMediaProps>`
  margin-bottom: 1rem;
  position: relative;

  ${variables.breakpoints.md} {
    margin-bottom: 0;
    position: ${(p) => (p.isRelative ? 'relative' : 'static')};
  }
`;

type ImageContainerProps = {
  isAnchoredLeft: boolean;
};

const ImageContainer = styled.div<ImageContainerProps>`
  /* margin-bottom: 4rem; */

  ${variables.breakpoints.md} {
    margin-bottom: 0;
    position: absolute;
    left: 1.25rem;
    right: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
  }

  ${variables.breakpoints.xl} {
    left: 2rem;
    right: 2rem;
  }
`;

type VideoContainerProps = {
  isAnchoredLeft: boolean;
  isSquare?: boolean;
};

const VideoContainer = styled.div<VideoContainerProps>`
  height: 0;
  padding-bottom: ${(p) => (p.isSquare ? '100%' : '56.25%')};
  position: relative;
  width: 100%;

  ${variables.breakpoints.md} {
    padding-bottom: ${(p) => (p.isSquare ? '50%' : '28.125%')};
    position: absolute;
    left: ${(p) => (p.isAnchoredLeft ? 0 : 'auto')};
    right: ${(p) => (p.isAnchoredLeft ? 'auto' : 0)};
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
  }

  ${variables.breakpoints.xxl} {
    padding-bottom: ${(p) => (p.isSquare ? '33.333333%' : '18.75%')};
    left: 50%;
    right: auto;
    width: 33.333333%;
  }
`;

const FiftyFiftyBlockText = styled(Block)`
  margin-bottom: 1rem;
  position: relative;
  z-index: 10;

  ${variables.breakpoints.md} {
    margin-bottom: 0;
  }
`;
