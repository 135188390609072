import Slider from 'react-slick';
import styled from 'styled-components';
import variables from '../../helpers/variables';

const StyledSlider = styled(Slider)`
  & .slick-list {
    margin-bottom: -4rem;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: -4rem;

    ${variables.breakpoints.md} {
      margin-left: -1.25rem;
      margin-right: -1.25rem;
    }

    ${variables.breakpoints.xl} {
      margin-left: -2rem;
      margin-right: -2rem;
    }
  }

  & .slick-slide {
    margin-bottom: 4rem;
    margin-top: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;

    ${variables.breakpoints.md} {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }

    ${variables.breakpoints.xl} {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  & .slick-dots {
    margin-top: 1.25rem;
    position: static;

    & > li {
      height: 1.125rem;
      width: 1.125rem;

      & > button {
        height: 1.125rem;
        width: 1.125rem;

        &::before {
          background-color: ${variables.css.color.white};
          border-radius: 9999px;
          content: '';
          display: block;
          height: 1.125rem;
          opacity: 0.25;
          width: 1.125rem;
        }
      }

      &.slick-active > button {
        &::before {
          opacity: 1;
        }
      }
    }
  }
`;

const Slide = styled.div`
  outline: none !important;
  position: relative;
`;

const SlideImage = styled.div`
  height: 0;
  margin-left: auto;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  z-index: -1;

  & .gatsby-image-wrapper {
    position: absolute !important;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  ${variables.breakpoints.md} {
    filter: brightness(75%);
    padding-bottom: 50.625%; // 56.25% * width
    width: 90%;
  }

  & picture {
    display: flex;
    height: 100%;
    width: 100%;
  }

  & img {
    object-fit: cover;
  }
`;

const SlideText = styled.div`
  margin-top: 2.25rem;

  ${variables.breakpoints.md} {
    margin-top: 0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
  }

  ${variables.breakpoints.lg} {
    width: 66.666666%;
  }

  ${variables.breakpoints.xl} {
    width: 50%;
  }
`;

export default {
  Slide,
  SlideImage,
  SlideText,
  StyledSlider,
};
