import styled from 'styled-components';
import variables from '../../helpers/variables';
import Block from './Block';

const BlockButton = styled(Block)`
  margin-bottom: 1rem;

  ${variables.breakpoints.lg} {
    margin-bottom: 1.125rem;
  }
`;

export default BlockButton;
