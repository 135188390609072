import React, { FC } from 'react';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import Block from '../Shared/Block';
import H2 from '../Shared/H2';
import Image from '../Shared/Image';
import P from '../Shared/P';
import Row from '../Shared/Row';
import SectionWhite from '../Shared/SectionWhite';
import ImageCarousel from './ImageCarousel';

type Props = {
  title?: string;
  intro?: string;
  logos: any[];
};

const Logos: FC<Props> = ({ title, intro, logos }) => {
  return (
    <SectionWhite>
      <Row>
        <Block>
          {title && <H2 isColorInverted>{title}</H2>}
          {intro && <LogosP isColorInverted>{intro}</LogosP>}
          <StyledRow>
            {logos.map((logo, index) => (
              <LogosBlock key={index} mdWidth={1 / Math.min(5, logos.length)}>
                <Image image={logo.image} />
              </LogosBlock>
            ))}
          </StyledRow>
          <RowMobile>
            <Block>
              <StyledImageCarousel
                slides={logos}
                dots={false}
                containImage={true}
              />
            </Block>
          </RowMobile>
        </Block>
      </Row>
    </SectionWhite>
  );
};

export default Logos;

const StyledImageCarousel = styled(ImageCarousel)`
  & > .slick-slider img {
    object-fit: contain !important;
  }
`;

const StyledRow = styled(Row)`
  display: none;
  ${variables.breakpoints.md} {
    display: flex;
  }
`;

const RowMobile = styled(Row)`
  display: block;

  ${variables.breakpoints.md} {
    display: none;
  }
`;

const LogosP = styled(P)`
  margin-bottom: 2.25rem;

  ${variables.breakpoints.lg} {
    margin-bottom: 3rem;
  }
`;

const LogosBlock = styled(Block)`
  align-items: center;
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
  & .gatsby-image-wrapper {
    width: 100%;
  }
`;
