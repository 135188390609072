import styled from 'styled-components';
import variables from '../../helpers/variables';
import Row from './Row';

const RowButtons = styled(Row)`
  margin-bottom: -1rem;

  ${variables.breakpoints.lg} {
    margin-bottom: -1.125rem;
  }
`;

export default RowButtons;
