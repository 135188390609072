import styled from 'styled-components';
import variables from '../../helpers/variables';

type H2AltProps = {
  isColorInverted?: boolean;
};

const H2Alt = styled.h2<H2AltProps>`
  color: ${(p) =>
    p.isColorInverted ? variables.css.color.blue : variables.css.color.white};
  font-family: ${variables.css.fontFamily};
  font-size: ${variables.css.fontSize['3xl']};
  font-weight: ${variables.css.fontWeight.regular};
  line-height: 1;
  margin-bottom: 1.875rem;

  ${variables.breakpoints.lg} {
    font-size: ${variables.css.fontSize['4xl']};
    margin-bottom: 2.25rem;
  }
`;

export default H2Alt;
