import React, { FC } from 'react';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import Block from '../Shared/Block';
import H2 from '../Shared/H2';
import P from '../Shared/P';
import Row from '../Shared/Row';
import Section from '../Shared/Section';
import Subtitle from '../Shared/Subtitle';
import StyledSlider from '../Shared/StyledSlider';

type Props = {
  columns: any[];
};

const StatColumns: FC<Props> = ({ columns }) => {
  return (
    <Section>
      <StatColumnsRow>
        {columns.map((item, index) => (
          <StatColumnsBlock key={index} mdWidth={1 / 4}>
            {item.subtitle && (
              <Subtitle fontSize={variables.css.fontSize.md}>
                {item.subtitle}
              </Subtitle>
            )}
            {item.stat && <Stat>{item.stat}</Stat>}
            {item.text && <P>{item.text}</P>}
          </StatColumnsBlock>
        ))}
      </StatColumnsRow>
      <StatColumnsRowMobile>
        <Block>
          <StatSliderStyledSlider arrows={false} dots>
            {columns.map((item, index) => (
              <StyledSlider.Slide key={index}>
                <Subtitle fontSize={variables.css.fontSize.md}>
                  {item.subtitle}
                </Subtitle>
                <Stat>{item.stat}</Stat>
                <P>{item.text}</P>
              </StyledSlider.Slide>
            ))}
          </StatSliderStyledSlider>
        </Block>
      </StatColumnsRowMobile>
    </Section>
  );
};

export default StatColumns;

const StatSliderStyledSlider = styled(StyledSlider.StyledSlider)`
  & .slick-dots {
    text-align: left;
  }
`;

const StatSliderH2 = styled(H2)`
  font-weight: ${variables.css.fontWeight.light};
  font-size: 2rem;
  line-height: 3rem;
  text-transform: none;
`;

const StatColumnsRow = styled(Row)`
  margin-bottom: -4rem;
  display: none;
  ${variables.breakpoints.md} {
    margin-bottom: 0;
    display: flex;
  }
`;
const StatColumnsRowMobile = styled(Row)`
  display: flex;
  ${variables.breakpoints.md} {
    margin-bottom: 0;
    display: none;
  }
`;

const StatColumnsBlock = styled(Block)`
  margin-bottom: 4rem;

  ${variables.breakpoints.md} {
    margin-bottom: 0;
  }
`;

const Stat = styled(H2)`
  margin-bottom: 1.25rem;
  text-transform: none;
`;
