import { Link } from 'gatsby';
import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import useBlueMenuTrigger from '../../hooks/useBlueMenuTrigger';
import Block from '../Shared/Block';
import Button from '../Shared/Button';
import H3 from '../Shared/H3';
import Row from '../Shared/Row';
import Section from '../Shared/Section';
import Wysiwyg from '../Shared/Wysiwyg';

type Props = {
  tabbedText: any[];
  spaceBottom: true | null;
};

const TabbedText: FC<Props> = ({ tabbedText, spaceBottom }) => {
  const ref = useRef<HTMLDivElement>(null);

  const uniqueId = useBlueMenuTrigger(ref);

  const [currentTab, setCurrentTab] = useState(0);

  return (
    <SCTabbedText id={uniqueId} ref={ref}>
      <Row>
        <Block lgWidth={1 / 4}>
          <SCTabButtons>
            {tabbedText.map((item, i) => (
              <SCTabButton
                key={i}
                onClick={() => setCurrentTab(i)}
                isActive={i === currentTab}
              >
                {item.title}
              </SCTabButton>
            ))}
          </SCTabButtons>
        </Block>
        <Block lgWidth={3 / 4}>
          <SCTabs>
            {tabbedText.map((item, i) => (
              <SCTab key={i} isHidden={currentTab !== i}>
                <H3 isColorInverted>{item.title}</H3>
                <SCTabText
                  isColorBlack
                  dangerouslySetInnerHTML={{ __html: item.text }}
                />
                {item.link && (
                  <Button isColorInverted as={Link} to={item.link.url}>
                    {item.link.title}
                  </Button>
                )}
              </SCTab>
            ))}
          </SCTabs>
        </Block>
      </Row>
    </SCTabbedText>
  );
};

export default TabbedText;

const SCTabbedText = styled(Section)`
  background-color: ${variables.css.color.white};
`;

const SCTabButtons = styled.div`
  display: none;
  margin-bottom: -1rem;

  ${variables.breakpoints.lg} {
    display: block;
  }
`;

type SCTabButtonProps = {
  isActive: boolean;
};

const SCTabButton = styled(Button)<SCTabButtonProps>`
  color: ${(p) =>
    p.isActive ? variables.css.color.blue : variables.css.color.grey};
  display: block;
  margin-bottom: 1rem;
`;

const SCTabs = styled.div`
  margin-bottom: -4rem;
`;

type SCTabProps = {
  isHidden: boolean;
};

const SCTab = styled.div<SCTabProps>`
  margin-bottom: 4rem;

  ${variables.breakpoints.lg} {
    display: ${(p) => (p.isHidden ? 'none' : 'block')};
  }
`;

const SCTabText = styled(Wysiwyg)`
  margin-bottom: 0;
`;
