import styled from 'styled-components';

interface Props {
  containImage?: boolean;
}
const ImageBackground = styled.div<Props>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  & .gatsby-image-wrapper {
    position: absolute !important;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  & picture {
    display: flex;
    height: 100%;
    width: 100%;
  }

  & img {
    height: 100%;
    object-fit: ${(p) => (p.containImage ? 'contain !important' : 'cover')};
    width: 100%;
  }
`;

export default ImageBackground;
