import React, { FC } from 'react';
import styled from 'styled-components';
import variables from '../../../helpers/variables';
import H3 from '../../Shared/H3';
import Image from '../../Shared/Image';
import ImageBackground from '../../Shared/ImageBackground';
import P from '../../Shared/P';

type Props = {
  person: any;
  animationState: number;
  numAnimationStates: number;
};

const PeopleCarouselSlide: FC<Props> = ({
  person,
  animationState,
  numAnimationStates,
}) => {
  return (
    <Slide
      animationState={animationState}
      numAnimationStates={numAnimationStates}
    >
      <Container>
        <ImageContainer>
          <StyledImageBackground
            animationState={animationState}
            numAnimationStates={numAnimationStates}
          >
            <Image image={person.image} />
          </StyledImageBackground>
        </ImageContainer>
        <TextContainer>
          <Name>{person.name}</Name>
          <JobTitle color={person.textColour}>{person.jobTitle}</JobTitle>
        </TextContainer>
      </Container>
    </Slide>
  );
};

export default PeopleCarouselSlide;

function getTranslateX(animationState: number, numAnimationStates: number) {
  switch (animationState) {
    case 0:
      return 0;
    case 1:
      return -250;
    default:
      return numAnimationStates * 250 - animationState * 250;
  }
}

type SlideProps = {
  animationState: number;
  numAnimationStates: number;
};

const Slide = styled.div.attrs((p: SlideProps) => ({
  style: {
    transform: `translateX(${getTranslateX(
      p.animationState,
      p.numAnimationStates
    )}px)`,
  },
}))<SlideProps>`
  height: 425px !important;
  margin-right: 25px;
  opacity: ${(p) => (p.animationState === 2 ? 0 : 1)};
  outline: none !important;
  position: absolute;
  left: 0;
  top: 0;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  width: 225px !important;
`;

const Container = styled.div`
  position: relative;
`;

const ImageContainer = styled.div`
  height: 300px;
  overflow: hidden;
  position: relative;
  width: 225px;
  z-index: -1;
`;

type StyledImageBackgroundProps = {
  animationState: number;
  numAnimationStates: number;
};

// prettier-ignore
const StyledImageBackground = styled(ImageBackground)<StyledImageBackgroundProps>`
  ${variables.breakpoints.md} {
    transform: ${(p) => (p.animationState === p.numAnimationStates - 1 ? 'scale(1.25)' : 'none')};
    transform-origin: top;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

const TextContainer = styled.div`
  margin-top: -1rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 20;
`;

const Name = styled(H3)`
  width: min-content;

  ${variables.breakpoints.lg} {
    font-size: ${variables.css.fontSize['2xl']};
    margin-bottom: 1.5rem;
  }

  ${variables.breakpoints.xl} {
    font-size: ${variables.css.fontSize['3xl']};
    margin-bottom: 1.875rem;
  }
`;

type JobTitleProps = {
  color: string;
};

const JobTitle = styled(P)<JobTitleProps>`
  color: ${(p) => variables.css.color[p.color]};
  font-weight: ${variables.css.fontWeight.black};
`;
