import styled from 'styled-components';
import variables from '../../helpers/variables';
import Section from './Section';

const SectionHero = styled(Section)`
  padding-top: 2rem;

  ${variables.breakpoints.md} {
    padding-top: 2rem;
  }

  ${variables.breakpoints.lg} {
    padding-top: 4rem;
  }
`;

export default SectionHero;
