import React, { FC, Fragment, useRef } from 'react';
import styled from 'styled-components';
import QuoteIcon from '../../assets/svg/quote.svg';
import variables from '../../helpers/variables';
import useBlueMenuTrigger from '../../hooks/useBlueMenuTrigger';
import Block from '../Shared/Block';
import P from '../Shared/P';
import Row from '../Shared/Row';
import Section from '../Shared/Section';

type Props = {
  quote?: string;
  quoteBy?: string;
  jobTitle?: string;
  spaceBottom: true | null;
};

const Testimonial: FC<Props> = ({ quote, quoteBy, jobTitle, spaceBottom }) => {
  const ref = useRef<HTMLDivElement>(null);

  const uniqueId = useBlueMenuTrigger(ref);

  return (
    <TestimonialSection id={uniqueId} ref={ref} spaceBottom={spaceBottom}>
      <Row>
        <Block>
          <Container>
            <QuoteIconContainer>
              <QuoteIcon />
            </QuoteIconContainer>
            <Quote>{quote}</Quote>
            <QuoteBy>
              <Bold>{quoteBy}</Bold>
              {jobTitle && <Fragment> &ndash; {jobTitle}</Fragment>}
            </QuoteBy>
          </Container>
        </Block>
      </Row>
    </TestimonialSection>
  );
};

export default Testimonial;

type TestimonialSectionProps = {
  spaceBottom: true | null;
};

const TestimonialSection = styled(Section)<TestimonialSectionProps>`
  background-color: ${variables.css.color.white};
  ${(p) => p.spaceBottom === null && 'padding-bottom: 0'};
`;

const Container = styled.div`
  border-top: 2px solid rgba(${variables.rgbCS.black}, 0.25);
  position: relative;
  width: 100%;
`;

const QuoteIconContainer = styled.div`
  margin-top: 2rem;

  ${variables.breakpoints.md} {
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const Quote = styled(P)`
  color: ${variables.css.color.blue};
  font-size: ${variables.css.fontSize['3xl']};
  font-weight: ${variables.css.fontWeight.regular};
  margin-top: 2rem;

  ${variables.breakpoints.md} {
    margin-left: 8rem;
    margin-right: 3rem;
  }
`;

const QuoteBy = styled(P)`
  color: ${variables.css.color.black};

  ${variables.breakpoints.md} {
    margin-left: 8rem;
    margin-right: 3rem;
  }
`;

const Bold = styled.span`
  font-weight: ${variables.css.fontWeight.bold};
`;
