import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import ImageBackground from './ImageBackground';

const VideoMaskPlanet = () => {
  return (
    <ImageBackground>
      <StaticImage src='../../assets/img/planet_mask.png' alt='' />
    </ImageBackground>
  );
};

export default VideoMaskPlanet;
