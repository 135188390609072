import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import variables from '../../helpers/variables';
import Block from '../Shared/Block';
import Button from '../Shared/Button';
import H2 from '../Shared/H2';
import H3 from '../Shared/H3';
import ImageBackground from '../Shared/ImageBackground';
import P from '../Shared/P';
import Row from '../Shared/Row';
import Section from '../Shared/Section';
import Subtitle from '../Shared/Subtitle';

type Props = {
  title?: string;
  subtitle?: string;
  text?: string;
  link?: any;
};

const Orbit: FC<Props> = ({ title, subtitle, text, link }) => {
  const [count, setCount] = useState<number>();
  // const [countTicker, setCountTicker] = useState(0);

  useEffect(() => {
    // We initialise state here for the sake of the animation.
    // We need the component to mount first, before setting state.
    setCount(0);

    const intervalCount = setInterval(() => {
      setCount((count) => count + 1);
    }, 4500);

    // const intervalCountTicker = setInterval(() => {
    //   setCountTicker((tickerCount) => tickerCount + 1);
    // }, 1250);

    return () => {
      clearInterval(intervalCount);
      // clearInterval(intervalCountTicker);
    };
  }, []);

  return (
    <OrbitSection>
      <Row>
        <Block>
          <OrbitTextCenter>
            {title && <OrbitTextCenterH2>{title}</OrbitTextCenterH2>}
            {subtitle && (
              <Subtitle fontSize={variables.css.fontSize.xl}>
                {subtitle}
              </Subtitle>
            )}
            {text && <P>{text}</P>}
          </OrbitTextCenter>
        </Block>
      </Row>
      <AnimationContainer>
        <Planet>
          <StaticImage src='../../assets/img/planet.png' alt='' />
        </Planet>
        <EllipseContainerBackMobile>
          <StaticImage src='../../assets/img/ellipse-mobile-back.png' alt='' />
        </EllipseContainerBackMobile>
        <EllipseContainerBackTablet>
          <ImageBackground>
            <StaticImage
              src='../../assets/img/ellipse-back-tablet-v3.png'
              alt=''
            />
          </ImageBackground>
        </EllipseContainerBackTablet>
        <EllipseContainerBack>
          <ImageBackground>
            <StaticImage src='../../assets/img/ellipse-back-v3.png' alt='' />
          </ImageBackground>
        </EllipseContainerBack>
        <EllipseContainerFrontMobile>
          <StaticImage src='../../assets/img/ellipse-mobile-front.png' alt='' />
        </EllipseContainerFrontMobile>
        <EllipseContainerFrontTablet>
          <ImageBackground>
            <StaticImage
              src='../../assets/img/ellipse-front-tablet-v3.png'
              alt=''
            />
          </ImageBackground>
        </EllipseContainerFrontTablet>
        <EllipseContainerFront>
          <ImageBackground>
            <StaticImage src='../../assets/img/ellipse-front-v3.png' alt='' />
          </ImageBackground>
        </EllipseContainerFront>
        <Text>
          {/* <TextLine1>
              <OrbitSubtitle fontSize={variables.css.fontSize.md}>
                <OrbitSubtitleText animationState={countTicker % 3}>
                  Brand
                </OrbitSubtitleText>
                <OrbitSubtitleText animationState={(countTicker + 1) % 3}>
                  SEO
                </OrbitSubtitleText>
                <OrbitSubtitleText animationState={(countTicker + 2) % 3}>
                  PPC
                </OrbitSubtitleText>
              </OrbitSubtitle>
            </TextLine1> */}
          <TextLine2>Performance</TextLine2>
          <TextLine3 fontSize={variables.css.fontSize.lg}>
            Beyond Today
          </TextLine3>
          {link && (
            <Button as={Link} to={link.url}>
              {link.title}
            </Button>
          )}
        </Text>
        <SatelliteContainer type='A' rotateByAngle={20}>
          <Satellite animationState={count % 4}>
            <StaticImage src='../../assets/img/satellite.png' alt='' />
          </Satellite>
        </SatelliteContainer>
        <SatelliteContainer type='B' rotateByAngle={-20}>
          <Satellite animationState={(count + 1) % 4}>
            <StaticImage src='../../assets/img/satellite.png' alt='' />
          </Satellite>
        </SatelliteContainer>
        <SatelliteContainer type='B' rotateByAngle={160}>
          <Satellite animationState={(count + 2) % 4}>
            <StaticImage src='../../assets/img/satellite.png' alt='' />
          </Satellite>
        </SatelliteContainer>
        <SatelliteContainer type='A' rotateByAngle={200}>
          <Satellite animationState={(count + 3) % 4}>
            <StaticImage src='../../assets/img/satellite.png' alt='' />
          </Satellite>
        </SatelliteContainer>
        <SatelliteTextTopLeft isVisibleMobile={count % 4 === 0}>
          Data
        </SatelliteTextTopLeft>
        <SatelliteTextBottomLeft isVisibleMobile={count % 4 === 1}>
          Technology
        </SatelliteTextBottomLeft>
        <SatelliteTextTopRight isVisibleMobile={count % 4 === 2}>
          Creativity
        </SatelliteTextTopRight>
        <SatelliteTextBottomRight isVisibleMobile={count % 4 === 3}>
          Strategy
        </SatelliteTextBottomRight>
      </AnimationContainer>
    </OrbitSection>
  );
};

export default Orbit;

function getXMobile(y: number) {
  return Math.sqrt(Math.pow(150, 2) * (1 - Math.pow(y, 2) / Math.pow(75, 2)));
}

function getYMobile(x: number) {
  return Math.sqrt(Math.pow(75, 2) * (1 - Math.pow(x, 2) / Math.pow(150, 2)));
}

function getXTablet(y: number) {
  return Math.sqrt(Math.pow(250, 2) * (1 - Math.pow(y, 2) / Math.pow(100, 2)));
}

function getYTablet(x: number) {
  return Math.sqrt(Math.pow(100, 2) * (1 - Math.pow(x, 2) / Math.pow(250, 2)));
}

function getXDesktop(y: number) {
  return Math.sqrt(Math.pow(400, 2) * (1 - Math.pow(y, 2) / Math.pow(160, 2)));
}

function getYDesktop(x: number) {
  return Math.sqrt(Math.pow(160, 2) * (1 - Math.pow(x, 2) / Math.pow(400, 2)));
}

function createKeyframe(frame: number, x: number, y: number) {
  return `${frame}%{transform:translate(${x.toFixed(1)}px,${y.toFixed(1)}px)}`;
}

// prettier-ignore
function createKeyframeTabletDesktop(frame: number, modifier: number, x: number, y: number) {
  return `${(frame / 8 + modifier).toFixed(2)}%{transform:translate(${x.toFixed(1)}px,${y.toFixed(1)}px)}\n`;
}

function getKeyframesMobileBottomLeft() {
  let frames = '';

  // Given y, (-150, 0) to (-90, 60).
  for (let frame = 0; frame < 50; frame++) {
    const y = frame * 1.2;
    const x = -getXMobile(y);
    frames += createKeyframe(frame, x, y);
  }

  // Given x, (-90, 60) to (0, 75).
  for (let frame = 50; frame <= 100; frame++) {
    const x = frame * 1.8 - 180;
    const y = getYMobile(x);
    frames += createKeyframe(frame, x, y);
  }

  return frames;
}

function getKeyframesMobileBottomRight() {
  let frames = '';

  // Given x, (0, 75) to (90, 60).
  for (let frame = 0; frame < 50; frame++) {
    const x = frame * 1.8;
    const y = getYMobile(x);
    frames += createKeyframe(frame, x, y);
  }

  // Given y, (90, 60) to (150, 0).
  for (let frame = 50; frame <= 100; frame++) {
    const y = frame * -1.2 + 120;
    const x = getXMobile(y);
    frames += createKeyframe(frame, x, y);
  }

  return frames;
}

function getKeyframesMobileTopRight() {
  let frames = '';

  // Given y, (150, 0) to (90, -60).
  for (let frame = 0; frame < 50; frame++) {
    const y = frame * -1.2;
    const x = getXMobile(y);
    frames += createKeyframe(frame, x, y);
  }

  // Given x, (90, -60) to (0, -75).
  for (let frame = 50; frame <= 100; frame++) {
    const x = frame * -1.8 + 180;
    const y = -getYMobile(x);
    frames += createKeyframe(frame, x, y);
  }

  return frames;
}

function getKeyframesMobileTopLeft() {
  let frames = '';

  // Given x, (0, -75) to (-90, -60).
  for (let frame = 0; frame < 50; frame++) {
    const x = frame * -1.8;
    const y = -getYMobile(x);
    frames += createKeyframe(frame, x, y);
  }

  // Given y, (-90, -60) to (-150, 0).
  for (let frame = 50; frame <= 100; frame++) {
    const y = frame * 1.2 - 120;
    const x = -getXMobile(y);
    frames += createKeyframe(frame, x, y);
  }

  return frames;
}

function getIncrementAdjustmentXY(midPointX: number, midPointY: number) {
  const incrementX = midPointX / 50;
  const incrementY = midPointY / 50;
  const adjustmentX = incrementX * 100;
  const adjustmentY = incrementY * 100;

  return [incrementX, incrementY, adjustmentX, adjustmentY];
}

function getKeyframesTablet() {
  const midPointX = 175;
  const midPointY = 71.41428429;
  const [
    incrementX,
    incrementY,
    adjustmentX,
    adjustmentY,
  ] = getIncrementAdjustmentXY(midPointX, midPointY);

  let frames = '';

  // Pause.
  frames += createKeyframeTabletDesktop(0, 0, -250, 0);
  frames += createKeyframeTabletDesktop(399, 0, -250, 0);

  // Bottom left. Given y, (-400, 0) to (-320, 96).
  for (let frame = 0; frame < 50; frame++) {
    const y = frame * incrementY;
    const x = -getXTablet(y);
    frames += createKeyframeTabletDesktop(frame, 50, x, y);
  }

  // Bottom left. Given x, (-320, 96) to (0, 160).
  for (let frame = 50; frame <= 99; frame++) {
    const x = frame * incrementX - adjustmentX;
    const y = getYTablet(x);
    frames += createKeyframeTabletDesktop(frame, 50, x, y);
  }

  // Bottom right. Given x, (0, 160) to (320, 96).
  for (let frame = 0; frame < 50; frame++) {
    const x = frame * incrementX;
    const y = getYTablet(x);
    frames += createKeyframeTabletDesktop(frame, 62.5, x, y);
  }

  // Bottom right. Given y, (320, 96) to (400, 0).
  for (let frame = 50; frame <= 99; frame++) {
    const y = frame * -incrementY + adjustmentY;
    const x = getXTablet(y);
    frames += createKeyframeTabletDesktop(frame, 62.5, x, y);
  }

  // Top right. Given y, (400, 0) to (320, -96).
  for (let frame = 0; frame < 50; frame++) {
    const y = frame * -incrementY;
    const x = getXTablet(y);
    frames += createKeyframeTabletDesktop(frame, 75, x, y);
  }

  // Top right. Given x, (320, -96) to (0, -160).
  for (let frame = 50; frame <= 99; frame++) {
    const x = frame * -incrementX + adjustmentX;
    const y = -getYTablet(x);
    frames += createKeyframeTabletDesktop(frame, 75, x, y);
  }

  // Top left. Given x, (0, -160) to (-320, -96).
  for (let frame = 0; frame < 50; frame++) {
    const x = frame * -incrementX;
    const y = -getYTablet(x);
    frames += createKeyframeTabletDesktop(frame, 87.5, x, y);
  }

  // Top left. Given y, (-320, -96) to (-400, 0).
  for (let frame = 50; frame <= 100; frame++) {
    const y = frame * incrementY - adjustmentY;
    const x = -getXTablet(y);
    frames += createKeyframeTabletDesktop(frame, 87.5, x, y);
  }

  return frames;
}

function getKeyframesDesktop() {
  const midPointX = 280;
  const midPointY = 114.262855;
  const [
    incrementX,
    incrementY,
    adjustmentX,
    adjustmentY,
  ] = getIncrementAdjustmentXY(midPointX, midPointY);

  let frames = '';

  // Pause.
  frames += createKeyframeTabletDesktop(0, 0, -400, 0);
  frames += createKeyframeTabletDesktop(399, 0, -400, 0);

  // Bottom left. Given y, (-400, 0) to (-320, 96).
  for (let frame = 0; frame < 50; frame++) {
    const y = frame * incrementY;
    const x = -getXDesktop(y);
    frames += createKeyframeTabletDesktop(frame, 50, x, y);
  }

  // Bottom left. Given x, (-320, 96) to (0, 160).
  for (let frame = 50; frame <= 99; frame++) {
    const x = frame * incrementX - adjustmentX;
    const y = getYDesktop(x);
    frames += createKeyframeTabletDesktop(frame, 50, x, y);
  }

  // Bottom right. Given x, (0, 160) to (320, 96).
  for (let frame = 0; frame < 50; frame++) {
    const x = frame * incrementX;
    const y = getYDesktop(x);
    frames += createKeyframeTabletDesktop(frame, 62.5, x, y);
  }

  // Bottom right. Given y, (320, 96) to (400, 0).
  for (let frame = 50; frame <= 99; frame++) {
    const y = frame * -incrementY + adjustmentY;
    const x = getXDesktop(y);
    frames += createKeyframeTabletDesktop(frame, 62.5, x, y);
  }

  // Top right. Given y, (400, 0) to (320, -96).
  for (let frame = 0; frame < 50; frame++) {
    const y = frame * -incrementY;
    const x = getXDesktop(y);
    frames += createKeyframeTabletDesktop(frame, 75, x, y);
  }

  // Top right. Given x, (320, -96) to (0, -160).
  for (let frame = 50; frame <= 99; frame++) {
    const x = frame * -incrementX + adjustmentX;
    const y = -getYDesktop(x);
    frames += createKeyframeTabletDesktop(frame, 75, x, y);
  }

  // Top left. Given x, (0, -160) to (-320, -96).
  for (let frame = 0; frame < 50; frame++) {
    const x = frame * -incrementX;
    const y = -getYDesktop(x);
    frames += createKeyframeTabletDesktop(frame, 87.5, x, y);
  }

  // Top left. Given y, (-320, -96) to (-400, 0).
  for (let frame = 50; frame <= 100; frame++) {
    const y = frame * incrementY - adjustmentY;
    const x = -getXDesktop(y);
    frames += createKeyframeTabletDesktop(frame, 87.5, x, y);
  }

  return frames;
}

const animateMobileBottomLeft = keyframes`${getKeyframesMobileBottomLeft()}`;
const animateMobileBottomRight = keyframes`${getKeyframesMobileBottomRight()}`;
const animateMobileTopRight = keyframes`${getKeyframesMobileTopRight()}`;
const animateMobileTopLeft = keyframes`${getKeyframesMobileTopLeft()}`;
const animateTablet = keyframes`${getKeyframesTablet()}`;
const animateDesktop = keyframes`${getKeyframesDesktop()}`;
const animateSatelliteContainerA = keyframes`
  0%   { z-index: 14 }
  55%  { z-index: 14 }
  56%  { z-index: 8 }
  69%  { z-index: 8 }
  70%  { z-index: 14 }
  100% { z-index: 14 }
`;
const animateSatelliteContainerB = keyframes`
  0%   { z-index: 14 }
  80%  { z-index: 14 }
  81%  { z-index: 8 }
  94%  { z-index: 8 }
  95%  { z-index: 14 }
  100% { z-index: 14 }
`;
const animateSatelliteTextDesktop = keyframes`
  0%    { opacity: 0; visibility: hidden; }
  3%    { opacity: 1; visibility: visible; }
  50%   { opacity: 1; visibility: visible; }
  53%   { opacity: 0; visibility: hidden; }
  100%  { opacity: 0; visibility: hidden; }
`;

const OrbitSection = styled(Section)`
  overflow: hidden;
`;

const OrbitTextCenter = styled.div`
  text-align: center;
`;

const OrbitTextCenterH2 = styled(H2)`
  margin-bottom: 0.5rem;
`;

const AnimationContainer = styled.div`
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 300px;

  ${variables.breakpoints.md} {
    height: 390px;
    width: 390px;
  }

  ${variables.breakpoints.xl} {
    height: 585px;
    width: 585px;
  }
`;

const Planet = styled.div`
  position: relative;
  z-index: 10;
`;

const EllipseContainer = styled.div`
  position: absolute;
`;

const EllipseContainerBackMobile = styled(EllipseContainer)`
  height: 300px;
  left: 0;
  top: 0;
  width: 300px;
  z-index: 6;

  ${variables.breakpoints.md} {
    display: none;
  }
`;

const EllipseContainerBackTablet = styled(EllipseContainer)`
  display: none;
  height: 390px;
  left: -55px;
  top: 0;
  width: 500px;
  z-index: 6;

  ${variables.breakpoints.md} {
    display: block;
  }

  ${variables.breakpoints.xl} {
    display: none;
  }
`;

const EllipseContainerBack = styled(EllipseContainer)`
  display: none;
  height: 585px;
  left: -107.5px;
  top: 0;
  width: 800px;
  z-index: 6;

  ${variables.breakpoints.xl} {
    display: block;
  }
`;

const EllipseContainerFrontMobile = styled(EllipseContainer)`
  height: 300px;
  left: 0;
  top: 0;
  width: 300px;
  z-index: 12;

  ${variables.breakpoints.md} {
    display: none;
  }
`;

const EllipseContainerFrontTablet = styled(EllipseContainer)`
  display: none;
  height: 390px;
  left: -55px;
  top: 0;
  width: 500px;
  z-index: 12;

  ${variables.breakpoints.md} {
    display: block;
  }

  ${variables.breakpoints.xl} {
    display: none;
  }
`;

const EllipseContainerFront = styled(EllipseContainer)`
  display: none;
  height: 585px;
  left: -107.5px;
  top: 0;
  width: 800px;
  z-index: 12;

  ${variables.breakpoints.xl} {
    display: block;
  }
`;

const Text = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 300px;
  z-index: 20;

  ${variables.breakpoints.md} {
    height: 390px;
    width: 390px;
  }

  ${variables.breakpoints.xl} {
    height: 585px;
    width: 585px;
  }
`;

const TextLine1 = styled.div`
  display: inline-block;
  overflow: hidden;
  width: auto;
`;

const TextLine2 = styled(H3)`
  margin-bottom: 0.5rem;
`;

const TextLine3 = styled(Subtitle)`
  height: 1.5rem;
  margin-bottom: 0.5rem;
  position: relative;
  text-align: center;
  width: 300px;

  ${variables.breakpoints.md} {
    margin-bottom: 2rem;
    width: 390px;
  }

  ${variables.breakpoints.xl} {
    width: 585px;
  }
`;

const OrbitSubtitle = styled(Subtitle)`
  height: 1.5rem;
  margin-bottom: 0;
  position: relative;
  text-align: center;
  width: 300px;

  ${variables.breakpoints.md} {
    width: 390px;
  }

  ${variables.breakpoints.xl} {
    width: 585px;
  }
`;

type OrbitSubtitleSpanProps = {
  animationState: number;
};

const OrbitSubtitleText = styled.span<OrbitSubtitleSpanProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  transform: ${(p) =>
    p.animationState === 0
      ? 'translateY(100%)'
      : p.animationState === 1
      ? 'translateY(0)'
      : 'translateY(-100%)'};
  transition: ${(p) =>
    p.animationState === 0
      ? 'none'
      : 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)'};
`;

type SatelliteContainerProps = {
  type: 'A' | 'B';
  rotateByAngle: number;
};

const SatelliteContainer = styled.div<SatelliteContainerProps>`
  height: 300px;
  position: absolute;
  left: 0;
  top: 0;
  width: 300px;

  ${variables.breakpoints.md} {
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-name: ${(p) =>
      p.type === 'A' ? animateSatelliteContainerA : animateSatelliteContainerB};
    animation-timing-function: linear;
    height: 390px;
    transform: ${(p) => `rotate(${p.rotateByAngle}deg)`};
    width: 390px;
  }

  ${variables.breakpoints.xl} {
    height: 585px;
    width: 585px;
  }
`;

type SatelliteProps = {
  animationState: number;
};

const Satellite = styled.div<SatelliteProps>`
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-name: ${(p) =>
    p.animationState === 0
      ? animateMobileBottomLeft
      : p.animationState === 1
      ? animateMobileBottomRight
      : p.animationState === 2
      ? animateMobileTopRight
      : animateMobileTopLeft};
  animation-timing-function: linear;
  height: 40px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  top: 50%;
  width: 40px;
  z-index: ${(p) =>
    p.animationState === 0 || p.animationState === 1 ? 15 : 5};

  ${variables.breakpoints.md} {
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-name: ${animateTablet};
  }

  ${variables.breakpoints.xl} {
    animation-name: ${animateDesktop};
  }
`;

type SatelliteTextProps = {
  isVisibleMobile: boolean;
};

const SatelliteText = styled.p<SatelliteTextProps>`
  color: ${variables.css.color.white};
  font-family: ${variables.css.fontFamily};
  font-size: ${variables.css.fontSize.lg};
  font-weight: ${variables.css.fontWeight.black};
  opacity: ${(p) => (p.isVisibleMobile ? 1 : 0)};
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  transition: ${(p) =>
    p.isVisibleMobile
      ? 'opacity 0.3s 2s, visiblity 0s 0s'
      : 'opacity 0.3s, visibility 0s 0.3s'};
  visibility: ${(p) => (p.isVisibleMobile ? 'visible' : 'hidden')};
  z-index: 15;

  ${variables.breakpoints.md} {
    animation: 10s infinite ${animateSatelliteTextDesktop} linear;
    bottom: auto;
    left: auto;
    right: auto;
    top: auto;
    width: 100%;
  }
`;

const SatelliteTextTopLeft = styled(SatelliteText)`
  ${variables.breakpoints.md} {
    left: -235px;
    top: 50px;
  }

  ${variables.breakpoints.xl} {
    left: -375px;
    top: 100px;
  }
`;

const SatelliteTextBottomLeft = styled(SatelliteText)`
  ${variables.breakpoints.md} {
    bottom: 140px;
    left: -235px;
  }

  ${variables.breakpoints.xl} {
    bottom: 180px;
    left: -375px;
  }
`;

const SatelliteTextTopRight = styled(SatelliteText)`
  ${variables.breakpoints.md} {
    right: -235px;
    top: 50px;
  }

  ${variables.breakpoints.xl} {
    right: -375px;
    top: 100px;
  }
`;

const SatelliteTextBottomRight = styled(SatelliteText)`
  ${variables.breakpoints.md} {
    bottom: 140px;
    right: -235px;
  }

  ${variables.breakpoints.xl} {
    bottom: 180px;
    right: -375px;
  }
`;
