import { Link } from 'gatsby';
import React, { FC } from 'react';
import Block from '../Shared/Block';
import Button from '../Shared/Button';
import H2 from '../Shared/H2';
import Image from '../Shared/Image';
import P from '../Shared/P';
import Row from '../Shared/Row';
import Section from '../Shared/Section';
import StyledSlider from '../Shared/StyledSlider';

type Props = {
  slides?: any[];
};

const ContentSlider: FC<Props> = ({ slides }) => {
  return (
    <Section>
      <Row>
        <Block>
          <StyledSlider.StyledSlider arrows={false} dots>
            {slides?.map((slide, index) => (
              <StyledSlider.Slide key={index}>
                <StyledSlider.SlideImage>
                  <Image image={slide.image} />
                </StyledSlider.SlideImage>
                <StyledSlider.SlideText>
                  <H2>{slide.title}</H2>
                  <P>{slide.intro}</P>
                  <Button as={Link} to={slide.link?.url}>
                    {slide.link?.title}
                  </Button>
                </StyledSlider.SlideText>
              </StyledSlider.Slide>
            ))}
          </StyledSlider.StyledSlider>
        </Block>
      </Row>
    </Section>
  );
};

export default ContentSlider;
