import styled from 'styled-components';
import variables from '../../helpers/variables';

type H3Props = {
  isColorInverted?: boolean;
};

const H3 = styled.h3<H3Props>`
  color: ${(p) =>
    p.isColorInverted ? variables.css.color.blue : variables.css.color.white};
  font-family: ${variables.css.fontFamily};
  font-size: ${variables.css.fontSize['2xl']};
  font-weight: ${variables.css.fontWeight.black};
  line-height: ${variables.css.lineHeight};
  margin-bottom: 1.5rem;
  text-transform: uppercase;

  ${variables.breakpoints.lg} {
    font-size: ${variables.css.fontSize['3xl']};
    margin-bottom: 1.875rem;
  }
`;

export default H3;
