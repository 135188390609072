import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import Chevron from '../../../assets/svg/icon-chevron-down.svg';
import variables from '../../../helpers/variables';
import useBlueMenuTrigger from '../../../hooks/useBlueMenuTrigger';
import Block from '../../Shared/Block';
import Row from '../../Shared/Row';
import Section from '../../Shared/Section';
import KeyDeliverablesCarouselSlide from './KeyDeliverablesCarouselSlide';

type Props = {
  slides: {
    subtitle: string;
    title: string;
    description: string;
    image: string;
  }[];
  // bottomSpacing: boolean;
};

const KeyDeliverablesCarousel: FC<Props> = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  const uniqueId = useBlueMenuTrigger(ref);

  return (
    <StyledSection id={uniqueId} ref={ref}>
      <Row>
        <Block>
          <SlidesContainer currentSlide={currentSlide}>
            {slides.map((slide, i) => (
              <KeyDeliverablesCarouselSlide
                key={i}
                {...slide}
                isActive={currentSlide === i}
              />
            ))}
          </SlidesContainer>
          <Button
            onClick={() => setCurrentSlide((s) => s - 1)}
            disabled={currentSlide === 0}
          >
            <ChevronContainerLeft>
              <Chevron />
            </ChevronContainerLeft>
          </Button>
          <Button
            onClick={() => setCurrentSlide((s) => s + 1)}
            disabled={currentSlide === slides.length - 1}
          >
            <ChevronContainerRight>
              <Chevron />
            </ChevronContainerRight>
          </Button>
        </Block>
      </Row>
    </StyledSection>
  );
};

export default KeyDeliverablesCarousel;

const StyledSection = styled(Section)`
  background-color: ${variables.css.color.white};
  overflow: hidden;

  ${variables.breakpoints.md} {
    background-color: ${variables.css.color.offwhite};
  }
`;

type SlidesContainerProps = {
  currentSlide: number;
};

const SlidesContainer = styled.div<SlidesContainerProps>`
  margin-bottom: -4rem;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);

  ${variables.breakpoints.md} {
    display: flex;
    margin-bottom: 0;
    margin-left: -1rem;
    margin-right: -1rem;
    transform: translateX(${(p) => p.currentSlide * -(672 + 32)}px);
    width: 2816px; // (672px + 32px) * 4
  }

  ${variables.breakpoints.lg} {
    transform: translateX(${(p) => p.currentSlide * -(768 + 32)}px);
    width: 3200px; // (768px + 32px) * 4
  }
`;

const ChevronContainerLeft = styled.div`
  margin-right: 0.25rem;
  transform: rotate(90deg);
`;

const ChevronContainerRight = styled.div`
  margin-left: 0.25rem;
  transform: rotate(-90deg);
`;

const Button = styled.button`
  background-color: ${variables.css.color.blue};
  border: none;
  border-radius: 9999px;
  color: ${variables.css.color.white};
  cursor: pointer;
  display: none;
  height: 3rem;
  margin-right: 1.5rem;
  margin-top: 2rem;
  width: 3rem;

  &:focus {
    outline: none;
  }

  ${variables.breakpoints.md} {
    display: inline-block;
  }
`;
