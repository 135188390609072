import { Link } from 'gatsby';
import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import useBlueMenuTrigger from '../../hooks/useBlueMenuTrigger';
import Block from '../Shared/Block';
import Button from '../Shared/Button';
import Image from '../Shared/Image';
import ImageBackground from '../Shared/ImageBackground';
import P from '../Shared/P';
import Row from '../Shared/Row';
import Section from '../Shared/Section';

type Props = {
  image: any;
  title: string;
  text: string;
  link: any;
  spaceBottom: true | null;
};

const ConstrainedWidthImage: FC<Props> = ({
  image,
  title,
  text,
  link,
  spaceBottom,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const uniqueId = useBlueMenuTrigger(ref);

  return (
    <SCConstrainedWidthImage id={uniqueId} ref={ref} spaceBottom={spaceBottom}>
      <Row>
        <Block>
          <SCPosition>
            <SCImage>
              <ImageBackground>
                <Image image={image} />
              </ImageBackground>
            </SCImage>
            <SCTextContainer>
              <SCTitle>{title}</SCTitle>
              <SCText>{text}</SCText>
              {link && (
                <SCMargin>
                  <SCLink as={Link} to={link.url}>
                    {link.title}
                  </SCLink>
                </SCMargin>
              )}
            </SCTextContainer>
          </SCPosition>
        </Block>
      </Row>
    </SCConstrainedWidthImage>
  );
};

export default ConstrainedWidthImage;

type SCConstrainedWidthImageProps = {
  spaceBottom: true | null;
};

const SCConstrainedWidthImage = styled(Section)<SCConstrainedWidthImageProps>`
  background-color: ${variables.css.color.white};
  padding-bottom: ${(p) => (p.spaceBottom ? '4rem' : 0)};
  padding-top: 0;

  ${variables.breakpoints.md} {
    padding-bottom: ${(p) => (p.spaceBottom ? '6rem' : 0)};
  }

  ${variables.breakpoints.lg} {
    padding-bottom: ${(p) => (p.spaceBottom ? '8rem' : 0)};
  }

  ${variables.breakpoints.xxxl} {
    padding-bottom: ${(p) => (p.spaceBottom ? '16rem' : 0)};
  }
`;

const SCPosition = styled.div`
  position: relative;
`;

const SCImage = styled.div`
  height: 0;
  margin-bottom: 2.25rem;
  padding-bottom: 56.25%;
  position: relative;

  ${variables.breakpoints.md} {
    margin-bottom: 0;
  }
`;

const SCTextContainer = styled.div`
  ${variables.breakpoints.md} {
    left: 0;
    padding-left: 2rem;
    padding-right: 2rem;
    position: absolute;
    top: 2rem;
    width: 75%;
  }

  ${variables.breakpoints.xl} {
    padding-left: 4rem;
    padding-right: 4rem;
    top: 8rem;
    width: 66.666667%;
  }

  ${variables.breakpoints.xxl} {
    width: 50%;
  }
`;

const SCTitle = styled.p`
  color: ${variables.css.color.blue};
  font-family: ${variables.css.fontFamily};
  font-size: 1.5rem;
  font-weight: ${variables.css.fontWeight.black};
  line-height: ${variables.css.lineHeight};
  margin-bottom: 2.25rem;
  text-transform: uppercase;

  ${variables.breakpoints.md} {
    color: ${variables.css.color.white};
    font-size: 1.875rem;
  }

  ${variables.breakpoints.xl} {
    font-size: 2.25rem;
  }
`;

const SCText = styled(P)`
  color: ${variables.css.color.black};

  ${variables.breakpoints.md} {
    color: ${variables.css.color.white};
  }
`;

const SCLink = styled(Button)`
  border-bottom-color: ${variables.css.color.blue};
  color: ${variables.css.color.blue};

  ${variables.breakpoints.md} {
    border-bottom-color: ${variables.css.color.white};
    color: ${variables.css.color.white};
  }
`;

const SCMargin = styled.div`
  margin-top: 2.25rem;
`;
