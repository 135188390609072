import React, { FC, Fragment, useRef } from 'react';
import styled from 'styled-components';
import QuoteIcon from '../../assets/svg/quote.svg';
import variables from '../../helpers/variables';
import useBlueMenuTrigger from '../../hooks/useBlueMenuTrigger';
import Block from '../Shared/Block';
import Image from '../Shared/Image';
import ImageBackground from '../Shared/ImageBackground';
import P from '../Shared/P';
import Row from '../Shared/Row';
import Section from '../Shared/Section';

type Props = {
  quote?: string;
  quoteBy?: string;
  jobTitle?: string;
  spaceBottom: true | null;
  mainImage?: any;
  secondaryImage?: any;
};

const TestimonialLarge: FC<Props> = ({ quote, quoteBy, jobTitle, spaceBottom, mainImage, secondaryImage }) => {
  const ref = useRef<HTMLDivElement>(null);
  const uniqueId = useBlueMenuTrigger(ref);

  return (
    <TestimonialSection id={uniqueId} ref={ref} spaceBottom={spaceBottom}>
      <Row>
        <Block>
          <Container>
            <QuoteContainer>
              <QuoteIconContainer>
                <QuoteIcon />
              </QuoteIconContainer>
              <Quote>{quote}</Quote>
              <QuoteBy>
                <Bold>{quoteBy}</Bold>
                {jobTitle && <Fragment> &ndash; {jobTitle}</Fragment>}
              </QuoteBy>
            </QuoteContainer>
            <ImageContainer>
              <LeftImage>
                <Image image={mainImage}/>
              </LeftImage>
              <RightImage>
                <ImageBackground>
                  <Image image={secondaryImage}/>
                </ImageBackground>
              </RightImage>
            </ImageContainer>
          </Container>
        </Block>
      </Row>
    </TestimonialSection>
  );
};

export default TestimonialLarge;

type TestimonialSectionProps = {
  spaceBottom: true | null;
};

const TestimonialSection = styled(Section)<TestimonialSectionProps>`
  background-color: ${variables.css.color.white};
  ${(p) => p.spaceBottom === null && 'padding-bottom: 0'};
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  
  ${variables.breakpoints.md} {
    display: flex;
    flex-direction: row;
  }
`;

const QuoteContainer = styled.div`
  ${variables.breakpoints.md} {
    padding-right: 2rem;
    max-width: 50%;
  }

  ${variables.breakpoints.xxl} {
    max-width: 45%;
  }
`;

const QuoteIconContainer = styled.div`
  margin-top: 2rem;
`;

const Quote = styled(P)`
  color: ${variables.css.color.black};
  font-size: ${variables.css.fontSize['2xl']};
  font-weight: ${variables.css.fontWeight.regular};
  margin-top: 2rem;
`;

const QuoteBy = styled(P)`
  color: ${variables.css.color.black};
`;

const Bold = styled.span`
  font-weight: ${variables.css.fontWeight.bold};
`;

const ImageContainer = styled.div`
  ${variables.breakpoints.md} {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    max-width: 50%;
  }
`;

const LeftImage = styled.div`
  text-align: center; 
  
  ${variables.breakpoints.md} {
    margin-top: -0.25rem;
    flex: 50%;
  }
`;

const RightImage = styled.div`
  display: none;
  position: relative;

  ${variables.breakpoints.xl} {
    margin-top: -8rem;
    margin-bottom: -8rem;
    margin-left: 2rem;
    display: block;
    flex: 50%;
  }
`;
