import React, { FC, Fragment } from 'react';
import AnimatedForm from './Flexible/AnimatedForm';
import CaseStudySlider from './Flexible/CaseStudySlider';
import ConstrainedWidthImage from './Flexible/ConstrainedWidthImage';
import ConstrainedWithImage from './Flexible/ConstrainedWidthImage';
import ContentBlocks from './Flexible/ContentBlocks';
import ContentSlider from './Flexible/ContentSlider';
import Ctas from './Flexible/Ctas';
import FiftyFifty from './Flexible/FiftyFifty';
import FullWidthHeading from './Flexible/FullWidthHeading';
import FullWidthText from './Flexible/FullWidthText';
import ImageCarousel from './Flexible/ImageCarousel';
import KeyDeliverablesCarousel from './Flexible/KeyDeliverablesCarousel/KeyDeliverablesCarousel';
import Logos from './Flexible/Logos';
import Orbit from './Flexible/Orbit';
import People from './Flexible/People';
import PeopleCarousel from './Flexible/PeopleCarousel/PeopleCarousel';
import PreviousWork from './Flexible/PreviousWork';
import StatColumns from './Flexible/StatColumns';
import StatSlider from './Flexible/StatSlider';
import TabbedText from './Flexible/TabbedText';
import Testimonial from './Flexible/Testimonial';
import TestimonialLarge from './Flexible/TestimonialLarge';
import Top200Table from './Flexible/Top200Table';

type Props = {
  prefix: string;
  components: any[];
};

const FlexibleContent: FC<Props> = ({ prefix, components }) => {
  return (
    <Fragment>
      {components?.map((component, index) => {
        let fieldType = component.fieldGroupName;
        fieldType = fieldType?.replace('ie_', '');
        
        switch (fieldType) {
          case `${prefix}_FlexibleComponents_FlexibleComponents_CaseStudySlider`:
            return (
              <CaseStudySlider
                key={index}
                caseStudies={component.caseStudies}
              />
            );
          case `${prefix}_FlexibleComponents_FlexibleComponents_ConstrainedWidthImage`:
            return (
              <ConstrainedWidthImage
                key={index}
                image={component.image}
                title={component.title}
                text={component.text}
                link={component.link}
                spaceBottom={component.spaceBottom}
              />
            );
          case `${prefix}_FlexibleComponents_FlexibleComponents_ContentBlocks`:
            return <ContentBlocks key={index} blocks={component.blocks} />;
          case `${prefix}_FlexibleComponents_FlexibleComponents_ContentSlider`:
            return (
              <ContentSlider key={index} slides={component.contentSlide} />
            );
          case `${prefix}_FlexibleComponents_FlexibleComponents_Ctas`:
            return (
              <Ctas
                key={index}
                ctas={component.ctas}
                columns={component.columns}
                isColorInverted={component.invertColours}
              />
            );
          case `${prefix}_FlexibleComponents_FlexibleComponents_Form`:
            return (
              <AnimatedForm
                key={index}
                splashTitle={component.title}
                splashSubtitle={component.subtitle}
                title={component.title}
                introduction={component.introduction}
                formTitle={component.formTitle}
                formTerms={component.formTerms}
                image={component.image}
                imageMobile={component.imageMobile}
                isFileUploadEnabled={component.isFileUploadEnabled}
                fileUploadLabel={component.fileUploadLabel}
              />
            );
          case `${prefix}_FlexibleComponents_FlexibleComponents_FiftyFifty`:
            return (
              <FiftyFifty
                key={index}
                subtitle={component.content?.subtitle}
                title={component.content?.title}
                text={component.content?.text}
                links={component.content?.links}
                imagesOrVideo={component.imagesOrVideo}
                images={component.images}
                video={component.video}
                mediaPosition={component.mediaPosition}
                mediaPositionMobile={component.mediaPositionMobile}
                spaceBottom={component.spaceBottom}
              />
            );
          case `${prefix}_FlexibleComponents_FlexibleComponents_FullWidthHeading`:
            return (
              <FullWidthHeading
                key={index}
                text={component.text}
                isPaddingBottom={component.bottomSpacing}
              />
            );
          case `${prefix}_FlexibleComponents_FlexibleComponents_FullWidthText`:
            return (
              <FullWidthText
                key={index}
                text={component.text}
                isPaddingBottom={component.bottomSpacing}
              />
            );
          case `${prefix}_FlexibleComponents_FlexibleComponents_ImageCarousel`:
            return (
              <ImageCarousel
                key={index}
                slides={component.carouselImages}
                dots
              />
            );
          case `${prefix}_FlexibleComponents_FlexibleComponents_KeyDeliverablesComponent`:
            return (
              <KeyDeliverablesCarousel key={index} slides={component.slides} />
            );
          case `${prefix}_FlexibleComponents_FlexibleComponents_Logos`:
            return (
              <Logos
                key={index}
                title={component.title}
                intro={component.intro}
                logos={component.logos}
              />
            );
          case `${prefix}_FlexibleComponents_FlexibleComponents_Orbit`:
            return (
              <Orbit
                key={index}
                title={component.title}
                subtitle={component.subtitle}
                text={component.text}
                link={component.link}
              />
            );
          case `${prefix}_FlexibleComponents_FlexibleComponents_People`:
            return <People key={index} people={component.people} />;
          case `${prefix}_FlexibleComponents_FlexibleComponents_PeopleCarousel`:
            return (
              <PeopleCarousel
                key={index}
                subtitle={component.subtitle}
                title={component.title}
                text={component.text}
                links={component.links}
                people={component.people}
              />
            );
          case `${prefix}_FlexibleComponents_FlexibleComponents_PreviousWork`:
            return (
              <PreviousWork
                key={index}
                caseStudy={component.caseStudy}
                insights={component.insights}
              />
            );
          case `${prefix}_FlexibleComponents_FlexibleComponents_StatColumns`:
            return <StatColumns key={index} columns={component.statColumns} />;
          case `${prefix}_FlexibleComponents_FlexibleComponents_StatSlider`:
            return <StatSlider key={index} stats={component.statSlider} />;
          case `${prefix}_FlexibleComponents_FlexibleComponents_TabbedText`:
            return (
              <TabbedText
                key={index}
                tabbedText={component.tabbedText}
                spaceBottom={component.spaceBottom}
              />
            );
            case `${prefix}_FlexibleComponents_FlexibleComponents_Testimonial`:
              return (
                <Testimonial
                  key={index}
                  quote={component.testimonial?.quote}
                  quoteBy={component.testimonial?.quoteBy}
                  jobTitle={component.testimonial?.jobTitle}
                  spaceBottom={component.spaceBottom}
                />
              );
              case `${prefix}_FlexibleComponents_FlexibleComponents_TestimonialLargeWithImage`:
                return (
                  <TestimonialLarge
                    key={index}
                    quote={component.testimonialLargeWithImage?.quote}
                    quoteBy={component.testimonialLargeWithImage?.quoteBy}
                    jobTitle={component.testimonialLargeWithImage?.jobTitle}
                    mainImage={component.testimonialLargeWithImage?.mainImage}
                    secondaryImage={component.testimonialLargeWithImage?.secondaryImage}
                    spaceBottom={component.spaceBottom}
                  />
                );
              case `${prefix}_FlexibleComponents_FlexibleComponents_Top200Table`:
                return (
                  <Top200Table 
                    key={index}
                    fields={component.fieldsToShow}
                  />
                );
          default:
            return (
              <pre key={index}>
                Undefined component: {component.fieldGroupName}
              </pre>
            );
        }
      })}
    </Fragment>
  );
};

export default FlexibleContent;
