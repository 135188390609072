import React, { FC, PropsWithChildren, useRef } from 'react';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import useBlueMenuTrigger from '../../hooks/useBlueMenuTrigger';
import Row from './Row';

type Props = {
  isOffWhite?: boolean;
};

const SectionWhite: FC<PropsWithChildren<Props>> = ({
  isOffWhite,
  children,
}) => {
  const refContainer = useRef<HTMLDivElement>(null);

  const uniqueId = useBlueMenuTrigger(refContainer);

  return (
    <Container id={uniqueId} ref={refContainer} isOffWhite={isOffWhite}>
      {children}
    </Container>
  );
};

export default SectionWhite;

type ContainerProps = {
  isOffWhite: boolean;
};

const Container = styled.div<ContainerProps>`
  background-color: ${(p) =>
    p.isOffWhite ? variables.css.color.offwhite : variables.css.color.white};
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 4rem;
  padding-top: 4rem;
  position: relative;

  margin-left: auto;
  margin-right: auto;

  ${variables.breakpoints.md} {
    padding-bottom: 6rem;
    padding-left: 6.25%;
    padding-right: 6.25%;
    padding-top: 6rem;
  }

  ${variables.breakpoints.lg} {
    padding-bottom: 8rem;
    padding-left: 15.625%;
    padding-right: 15.625%;
    padding-top: 8rem;
  }

  ${variables.breakpoints.xxxl} {
    padding-bottom: 16rem;
    padding-top: 16rem;

    & > ${Row} {
      margin-left: auto;
      margin-right: auto;
      max-width: 1920px;
    }
  }

  /* ${variables.breakpoints.xxl} {
    padding-bottom: 8rem;
    padding-left: 5.625%;
    padding-right: 5.625%;
    padding-top: 8rem;
  } */
`;
