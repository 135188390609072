import { format } from 'date-fns';
import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import Block from '../Shared/Block';
import Button from '../Shared/Button';
import H3 from '../Shared/H3';
import Image from '../Shared/Image';
import ImageBackground from '../Shared/ImageBackground';
import P from '../Shared/P';
import Row from '../Shared/Row';
import Section from '../Shared/Section';
import Subtitle from '../Shared/Subtitle';

type Props = {
  caseStudy: any;
  insights: any[];
};

const PreviousWork: FC<Props> = ({ caseStudy, insights }) => {
  return (
    <Section>
      <Row justifyContent='space-between'>
        <Block lgWidth={5 / 11}>
          <SCCaseStudy>
            <SCCaseStudyImage>
              <ImageBackground>
                <Image
                  image={
                    caseStudy.caseStudy.hero_case_study.heroCaseStudy.image
                  }
                />
              </ImageBackground>
            </SCCaseStudyImage>
            <Subtitle fontSize={variables.css.fontSize.lg}>
              {caseStudy.companyName}
            </Subtitle>
            <H3>{caseStudy.title}</H3>
            <P>{caseStudy.description}</P>
            <Button as={Link} to={caseStudy.caseStudy.uri}>
              Find out more
            </Button>
          </SCCaseStudy>
        </Block>
        <Block lgWidth={5 / 11}>
          <SCInsights>
            <SCInsightsList>
              {insights.map(
                (item, i) =>
                  item &&
                  item.article && (
                    <SCInsightsListItem key={i}>
                      <Subtitle fontSize={variables.css.fontSize.lg}>
                        {format(new Date(item.article.date), 'dd MMM')}
                      </Subtitle>
                      <SCInsightsListItemTitle>
                        {item.article.title}
                      </SCInsightsListItemTitle>
                      <Button as={Link} to={item.article.uri}>
                        Read more
                      </Button>
                    </SCInsightsListItem>
                  )
              )}
            </SCInsightsList>
          </SCInsights>
        </Block>
      </Row>
    </Section>
  );
};

export default PreviousWork;

const SCCaseStudy = styled.div`
  margin-bottom: 4rem;

  ${variables.breakpoints.lg} {
    margin-bottom: 0;
  }
`;

const SCCaseStudyImage = styled.div`
  height: 0;
  margin-bottom: 1.5rem;
  padding-bottom: 56.25%;
  position: relative;
`;

const SCInsights = styled.div``;

const SCInsightsList = styled.div`
  margin-bottom: -2.5rem;
`;

const SCInsightsListItem = styled.div`
  margin-bottom: 2.5rem;
`;

const SCInsightsListItemTitle = styled.div`
  color: ${variables.css.color.white};
  font-family: ${variables.css.fontFamily};
  font-size: ${variables.css.fontSize['xl']};
  font-weight: ${variables.css.fontWeight.regular};
  margin-bottom: 1rem;
  line-height: 1;

  ${variables.breakpoints.lg} {
    font-size: ${variables.css.fontSize['2xl']};
  }
`;
