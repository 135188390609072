import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import escapeSpecialCharacters from '../../helpers/escapeSpecialCharacters';
import variables from '../../helpers/variables';
import Block from '../Shared/Block';
import BlockButton from '../Shared/BlockButton';
import Button from '../Shared/Button';
import H2Alt from '../Shared/H2Alt';
import Row from '../Shared/Row';
import RowButtons from '../Shared/RowButtons';
import SectionWhite from '../Shared/SectionWhite';
import Subtitle from '../Shared/Subtitle';
import Wysiwyg from '../Shared/Wysiwyg';

type Props = {
  blocks: any;
};

const ContentBlocks: FC<Props> = ({ blocks }) => {
  return (
    <SectionWhite>
      <Row>
        {blocks.map((block, index) => (
          <PaddedBlock key={index} mdWidth={1 / 2}>
            {block.subtitle && (
              <Subtitle fontSize={variables.css.fontSize.md}>
                {block.subtitle}
              </Subtitle>
            )}
            {block.title && <H2Alt isColorInverted>{block.title}</H2Alt>}
            {block.wysiwyg && (
              <ContentBlocksWysiwyg
                dangerouslySetInnerHTML={{ __html: block.wysiwyg }}
              />
            )}
            {block.links && (
              <RowButtons>
                {block.links.map(
                  (item, index) =>
                    item.link && (
                      <BlockButton key={index}>
                        <Button as={Link} to={item.link.url} isColorInverted>
                          {escapeSpecialCharacters(item.link.title)}
                        </Button>
                      </BlockButton>
                    )
                )}
              </RowButtons>
            )}
          </PaddedBlock>
        ))}
      </Row>
    </SectionWhite>
  );
};

export default ContentBlocks;

const ContentBlocksWysiwyg = styled(Wysiwyg)`
  & > p {
    color: ${variables.css.color.black} !important;
  }
`;

const PaddedBlock = styled(Block)`
  padding-top: 2.375rem;
  padding-bottom: 2.375rem;
`;
