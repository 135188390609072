import styled from 'styled-components';
import variables from '../../helpers/variables';
import Wysiwyg from './Wysiwyg';

const FiftyFiftyWyswiygTitle = styled(Wysiwyg)`
  & > h2 {
    color: white;
    font-family: ${variables.css.fontFamily};
    font-size: ${variables.css.fontSize['3xl']};
    font-weight: ${variables.css.fontWeight.regular};
    line-height: 1.25;
    margin-bottom: 1.875rem;

    ${variables.breakpoints.lg} {
      font-size: ${variables.css.fontSize['4xl']};
      margin-bottom: 2.25rem;
    }
  }
`;

export default FiftyFiftyWyswiygTitle;
