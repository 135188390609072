import React, { FC } from 'react';
import { FieldInputProps } from 'react-final-form';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import Button from './Button';

type Props = {
  input: FieldInputProps<any, HTMLElement>;
  meta?: any;
  id: string;
  label: string;
};

const FileInput: FC<Props> = ({ input, id, label, meta }) => {
  var fullPath = input.value;
  if (fullPath) {
    var startIndex =
      fullPath.indexOf('\\') >= 0
        ? fullPath.lastIndexOf('\\')
        : fullPath.lastIndexOf('/');
    var filename = fullPath.substring(startIndex);
    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
      filename = filename.substring(1);
    }
  }
  return (
    <Container>
      <Button
        onClick={(e) => {
          e.preventDefault();
          document.getElementById(id).click();
        }}
      >
        {label}
      </Button>
      <StyledSpan>
        {filename ? `Selected: ${filename}` : 'No File Selected.'}
      </StyledSpan>
      <StyledInput {...input} id={id} type='file' accept='application/pdf' />
    </Container>
  );
};

const StyledSpan = styled.span`
  padding-left: 0.5rem;
`;

const StyledInput = styled.input`
  display: none;
`;

type LabelProps = {
  isMinimized: boolean;
  willShowError: boolean;
};

const Label = styled.label<LabelProps>`
  color: ${(p) =>
    p.willShowError ? variables.css.color.red : variables.css.color.white};
  opacity: ${(p) => (p.isMinimized ? '1' : '0.75')};
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0.25rem;
  transform: ${(p) =>
    p.isMinimized ? 'scale(0.75) translateY(0)' : 'scale(1) translateY(100%)'};
  transform-origin: top left;
  transition: opacity 0.2s, transform 0.2s;
`;

const Container = styled.div`
  margin-bottom: 1rem;
  padding-top: 2rem;
  position: relative;
  color: ${variables.css.color.white};
`;

export default FileInput;
