import React, { FC } from 'react';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import Block from '../Shared/Block';
import Person from '../Shared/Person';
import Row from '../Shared/Row';
import Section from '../Shared/Section';

type Props = {
  people?: any[];
};

const People: FC<Props> = ({ people }) => {
  return (
    <Section>
      <PeopleRow>
        {people?.map((person, i) => (
          <PeopleBlock key={i} xsWidth={1 / 2} mdWidth={1 / 3} lgWidth={1 / 4}>
            <Person person={person} />
          </PeopleBlock>
        ))}
      </PeopleRow>
    </Section>
  );
};

export default People;

const PeopleRow = styled(Row)`
  margin-bottom: -4rem;

  ${variables.breakpoints.md} {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  ${variables.breakpoints.xl} {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`;

const PeopleBlock = styled(Block)`
  margin-bottom: 4rem;

  ${variables.breakpoints.md} {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  ${variables.breakpoints.xl} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;
