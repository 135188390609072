import React, { FC } from 'react';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import { useSiteOptionsQuery } from '../../helpers/useSiteOptionsQuery';
type Props = {
  phoneNumber?: string;
  color?: string;
};

const ContactPhoneNumber: FC<Props> = ({ phoneNumber, color }) => {
  const data = useSiteOptionsQuery();

  const defaultPhoneNumber =
    data.wp.siteOptions.site_options.global.globalTelephone;

  const formattedPhoneNumber = phoneNumber
    ? formatNumber(phoneNumber)
    : formatNumber(defaultPhoneNumber);

  function formatNumber(number: string) {
    return number.replace(/\+(\d{2})(\d{3})(\d{3})(\d{4})/, '+$1 (0) $2 $3 $4');
  }

  return (
    <StyledP color={color}>
      <a
        href={`tel:${phoneNumber || defaultPhoneNumber}`}
        aria-label={`Call Mediaworks on ${formattedPhoneNumber}`}
      >
        {formattedPhoneNumber}
      </a>
    </StyledP>
  );
};

export default ContactPhoneNumber;

const StyledP = styled.p`
  color: ${(p) => (p.color ? p.color : variables.css.color.white)};
  font-family: ${variables.css.fontFamily};
  font-size: ${variables.css.fontSize.sm};
  margin-right: 1rem;
`;
