import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useSiteOptionsQuery } from '../helpers/useSiteOptionsQuery';
type Props = {
  pathname: string;
  seo: any;
  hreflang?: string;
};

const SEO: FC<Props> = ({ pathname, seo, hreflang }) => {
  // We cannot take advantage of absolute URLs passed from Yoast, because they're
  // specific to the CMS. As Gatsby is serverless and has no knowledge of where
  // it will be deployed, we must construct our own URLs for Gatsby.

  const data = useSiteOptionsQuery();

  const mwDomain = 'https://www.mediaworks.co.uk';
  const mwiDomain = 'https://www.mwi.ie';

  const isUk = process.env.GATSBY_MWSITE == "uk";
  const url = isUk
            ? mwDomain
            : mwiDomain;

  let hrefGB = isUk ? mwDomain + pathname : '';
  let hrefIE = isUk ? '' : mwiDomain + pathname;

  if (hreflang){
    if (isUk){
      hrefIE = mwiDomain + hreflang;
    }
    else {
      hrefGB = mwDomain + hreflang.replace('/ie/', '/');
    }
  }

  return (
    <Helmet>
      <html lang='en' />
      <meta charSet='utf-8' />
      <title>{seo.title}</title>
      <meta name='description' content={seo.metaDesc} />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta name='MobileOptimized' content='320' />
      <meta name='HandheldFriendly' content='True' />
      <meta name='format-detection' content='telephone=no' />
      <link rel='canonical' href={url + pathname} />
      <link rel="alternate" href={url + pathname} hrefLang="x-default" />
      {hrefGB != '' && (
        <link rel="alternate" href={hrefGB} hrefLang="en-gb" />
      )}
      {hrefIE != '' && (
        <link rel="alternate" href={hrefIE} hrefLang="en-ie" />
      )}
      <meta property='og:title' content={seo.opengraphTitle} />
      <meta property='og:type' content={seo.opengraphType} />
      <meta
        property='og:image'
        content={
          seo.opengraphImage !== null
            ? seo.opengraphImage.mediaItemUrl
            : `${process.env.GATSBY_CMS_URL}/wp-content/uploads/2021/02/placeholder.png`
        }
      />
      <meta property='og:url' content={url + pathname} />
      <meta property='og:description' content={seo.opengraphDescription} />
      <meta property='og:locale' content='en_GB' />
      <meta property='og:site_name' content={seo.opengraphSiteName} />
      <meta
        property='article:modified_time'
        content={seo.opengraphModifiedTime}
      />
      <meta
        property='article:published_time'
        content={seo.opengraphPublishedTime}
      />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@mediaworksuk' />
      <meta name='twitter:title' content={seo.twitterTitle} />
      <meta name='twitter:description' content={seo.twitterDescription} />
      <meta
        name='twitter:image'
        content={
          seo.twitterImage !== null
            ? seo.twitterImage.mediaItemUrl
            : `${process.env.GATSBY_CMS_URL}/wp-content/uploads/2021/02/placeholder.png`
        }
      />
      <script type='application/ld+json'>
        {seo.schema.raw.replace(/"\//g, `"${url}/`)}
      </script>
    </Helmet>
  );
};

export default SEO;
