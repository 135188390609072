import styled from 'styled-components';
import variables from '../../helpers/variables';

type SubtitleProps = {
  fontSize: string;
};

const Subtitle = styled.p<SubtitleProps>`
  color: ${variables.css.color.pink};
  margin-bottom: 1.25rem;
  font-family: ${variables.css.fontFamily};
  font-size: ${(p) => p.fontSize};
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
`;

export default Subtitle;
