import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import escapeSpecialCharacters from '../../helpers/escapeSpecialCharacters';
import variables from '../../helpers/variables';

type Props = {
  breadcrumbs: any[];
  isColorInverted?: boolean;
};

const Breadcrumb: FC<Props> = ({ breadcrumbs, isColorInverted }) => {
  return (
    <List>
      {breadcrumbs.map((breadcrumb, index) => (
        <Item isColourInverted={isColorInverted} key={index}>
          {index === breadcrumbs.length - 1 ? (
            escapeSpecialCharacters(breadcrumb.text)
          ) : (
            <Link to={breadcrumb.url}>
              {escapeSpecialCharacters(breadcrumb.text)}
            </Link>
          )}
        </Item>
      ))}
    </List>
  );
};

export default Breadcrumb;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-bottom: 4rem;
  
  ${variables.breakpoints.lg} {
    padding-top: 1rem;
  }
`;

interface ItemProps {
  isColourInverted?: boolean;
}

const Item = styled.li<ItemProps>`
  color: ${(props) =>
    props.isColourInverted
      ? variables.css.color.black
      : variables.css.color.white};
  display: inline-block;
  font-family: ${variables.css.fontFamily};
  font-size: ${variables.css.fontSize.xs};
  padding-right: 0.25rem;
  text-transform: uppercase;

  &::after {
    content: '>';
    padding-left: 0.25rem;
  }

  &:last-child::after {
    content: none;
  }
`;
