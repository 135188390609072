import React, { FC } from 'react';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import H3 from './H3';
import Image from './Image';
import ImageBackground from './ImageBackground';
import P from './P';

type Props = {
  person: any;
};

const Person: FC<Props> = ({ person }) => {
  return (
    <Container>
      <ImageContainer>
        <ImageBackground>
          <Image image={person.image} />
        </ImageBackground>
      </ImageContainer>
      <TextContainer>
        <Name>{person.name}</Name>
        <JobTitle color={person.textColour}>{person.jobTitle}</JobTitle>
      </TextContainer>
    </Container>
  );
};

export default Person;

const Container = styled.div`
  position: relative;
`;

const ImageContainer = styled.div`
  height: 0;
  padding-bottom: 133.333333%;
  position: relative;
  width: 100%;
`;

const TextContainer = styled.div`
  margin-top: -1rem;
  position: relative;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 20;
`;

const Name = styled(H3)`
  width: min-content;

  ${variables.breakpoints.lg} {
    font-size: ${variables.css.fontSize['2xl']};
    margin-bottom: 1.5rem;
  }

  ${variables.breakpoints.xl} {
    font-size: ${variables.css.fontSize['3xl']};
    margin-bottom: 1.875rem;
  }
`;

type JobTitleProps = {
  color: string;
};

const JobTitle = styled(P)<JobTitleProps>`
  color: ${(p) => variables.css.color[p.color]};
  font-weight: ${variables.css.fontWeight.black};
`;
