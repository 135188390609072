import { Link } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import escapeSpecialCharacters from '../../../helpers/escapeSpecialCharacters';
import variables from '../../../helpers/variables';
import Block from '../../Shared/Block';
import BlockButton from '../../Shared/BlockButton';
import Button from '../../Shared/Button';
import FiftyFiftyWysiwygText from '../../Shared/FiftyFiftyWysiwygText';
import FiftyFiftyWyswiygTitle from '../../Shared/FiftyFiftyWysiwygTitle';
import Row from '../../Shared/Row';
import RowButtons from '../../Shared/RowButtons';
import Section from '../../Shared/Section';
import Subtitle from '../../Shared/Subtitle';
import PeopleCarouselSlide from './PeopleCarouselSlide';

type Props = {
  people: any[];
  subtitle?: string;
  title?: string;
  text?: string;
  links?: any[];
};

const PeopleCarousel: FC<Props> = ({
  people,
  subtitle,
  title,
  text,
  links,
}) => {
  const [tick, setTick] = useState(0);

  useEffect(() => {
    const intervalTick = setInterval(() => {
      setTick((tick) => tick + 1);
    }, 1800);

    return () => {
      clearInterval(intervalTick);
    };
  }, []);

  return (
    <Section>
      <Row>
        <PeopleCarouselBlock mdWidth={1 / 2}>
          <div>
            {subtitle && (
              <Subtitle fontSize={variables.css.fontSize.md}>
                {subtitle}
              </Subtitle>
            )}
            {title && (
              <FiftyFiftyWyswiygTitle
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            {text && (
              <FiftyFiftyWysiwygText
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )}
            {links && (
              <RowButtons>
                {links?.map(
                  (item, index) =>
                    item.link && (
                      <BlockButton key={index}>
                        <Button as={Link} to={item.link.url}>
                          {escapeSpecialCharacters(item.link.title)}
                        </Button>
                      </BlockButton>
                    )
                )}
              </RowButtons>
            )}
          </div>
        </PeopleCarouselBlock>
        <Block mdWidth={1 / 2}>
          <Slider>
            <SliderTrack numSlides={people.length}>
              {people?.map((person, i) => (
                <PeopleCarouselSlide
                  key={i}
                  person={person}
                  animationState={(tick + i) % people.length}
                  numAnimationStates={people.length}
                />
              ))}
            </SliderTrack>
          </Slider>
        </Block>
      </Row>
    </Section>
  );
};

export default PeopleCarousel;

const PeopleCarouselBlock = styled(Block)`
  margin-bottom: 4rem;

  ${variables.breakpoints.md} {
    margin-bottom: 0;
    min-height: 425px;
  }
`;

const Slider = styled.div`
  overflow: hidden;
  position: relative;

  ${variables.breakpoints.md} {
    position: absolute;
    right: 0;
    top: 8rem;
    width: 50%;
    z-index: 10;

    &::after {
      background: linear-gradient(
        270deg,
        rgba(${variables.rgbCS.blue}, 0) 25%,
        ${variables.css.color.blue} 100%
      );
      content: ' ';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      top: 0;
      width: 225px;
    }
  }
`;

type SliderTrackProps = {
  numSlides: number;
};

const SliderTrack = styled.div<SliderTrackProps>`
  display: flex;
  height: 425px;
  position: relative;
  width: ${(p) => 250 * p.numSlides}px;
`;
