import styled from 'styled-components';
import variables from '../../helpers/variables';

const ButtonPink = styled.button`
  background-color: ${variables.css.color.pink};
  border: none;
  border-radius: 0;
  color: ${variables.css.color.white};
  cursor: pointer;
  display: inline-block;
  font-family: ${variables.css.fontFamily};
  font-size: ${variables.css.fontSize.sm};
  font-style: italic;
  font-weight: ${variables.css.fontWeight.black};
  padding: 1rem;
  text-transform: uppercase;

  ${variables.breakpoints.lg} {
    font-size: ${variables.css.fontSize.md};
  }
`;

export default ButtonPink;