import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import styled from 'styled-components';
import variables from '../../../helpers/variables';
import Image from '../../Shared/Image';
import ImageBackground from '../../Shared/ImageBackground';
import Subtitle from '../../Shared/Subtitle';
import Wysiwyg from '../../Shared/Wysiwyg';

type Props = {
  subtitle: string;
  title: string;
  description: string;
  image: string;
  isActive: boolean;
};

const KeyDeliverablesCarouselSlide: FC<Props> = ({
  subtitle,
  title,
  description,
  image,
  isActive,
}) => {
  const [translateY, setTranslateY] = useState(0);

  const cardRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const { offsetHeight: cardHeight } = cardRef.current;
      const { offsetHeight: textHeight } = textRef.current;
      setTranslateY(cardHeight - textHeight - 80);
    });

    resizeObserver.observe(cardRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <CardContainer>
      <Card ref={cardRef}>
        <TitleContainer
          ref={textRef}
          isActive={isActive}
          translateY={translateY}
        >
          <Subtitle fontSize={variables.css.fontSize.xl}>{subtitle}</Subtitle>
          <Title isActive={isActive}>{title}</Title>
        </TitleContainer>
        <DescriptionContainer isActive={isActive}>
          <Wysiwyg
            isColorBlack
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </DescriptionContainer>
        <ImageContainer isActive={isActive}>
          <ImageBackground>
            <Image image={image} />
          </ImageBackground>
        </ImageContainer>
      </Card>
    </CardContainer>
  );
};

export default KeyDeliverablesCarouselSlide;

type PropsIsActive = {
  isActive: boolean;
};

const CardContainer = styled.div`
  ${variables.breakpoints.md} {
    display: flex;
    flex-grow: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 672px;
  }

  ${variables.breakpoints.lg} {
    width: 768px;
  }
`;

const Card = styled.div`
  background-color: ${variables.css.color.white};
  border-radius: 0.75rem;
  margin-bottom: 4rem;
  overflow: hidden;
  position: relative;

  ${variables.breakpoints.md} {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    margin-bottom: 0;
    padding: 2.5rem;
  }
`;

type TitleContainerProps = {
  isActive: boolean;
  translateY: number;
};

const TitleContainer = styled.div<TitleContainerProps>`
  position: relative;

  ${variables.breakpoints.md} {
    transform: translateY(${(p) => (p.isActive ? 0 : p.translateY)}px);
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 100;
  }
`;

const Title = styled.p<PropsIsActive>`
  color: ${variables.css.color.blue};
  font-family: ${variables.css.fontFamily};
  font-size: ${variables.css.fontSize['2xl']};
  font-weight: ${variables.css.fontWeight.regular};
  line-height: 1.25;
  margin-bottom: 1.875rem;

  ${variables.breakpoints.md} {
    color: ${(p) =>
      p.isActive ? variables.css.color.blue : variables.css.color.white};
    font-size: ${variables.css.fontSize['3xl']};
    margin-bottom: 2.25rem;
    transition: color 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

const DescriptionContainer = styled.div<PropsIsActive>`
  margin-bottom: -2.25rem;
  transition-duration: 0.5s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  ${variables.breakpoints.md} {
    margin-bottom: -3rem;
    opacity: ${(p) => (p.isActive ? 1 : 0)};
    transform: translateY(${(p) => (p.isActive ? '0' : '2.5rem')});
  }
`;

const ImageContainer = styled.div<PropsIsActive>`
  display: none;
  opacity: ${(p) => (p.isActive ? 0 : 1)};
  transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);

  ${variables.breakpoints.md} {
    display: block;
  }
`;
