import axios from 'axios';
import rafSchedule from 'raf-schd';
import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components';
const SVG = require(`../../assets/svg/form.${process.env.GATSBY_MWSITE}.svg`);
import variables from '../../helpers/variables';
import useViewportWidth from '../../hooks/useViewportWidth';
import Block from '../Shared/Block';
import Button from '../Shared/Button';
import FileInput from '../Shared/FileInput';
import H2 from '../Shared/H2';
import Image from '../Shared/Image';
import ImageBackground from '../Shared/ImageBackground';
import Input from '../Shared/Input';
import P from '../Shared/P';
import Row from '../Shared/Row';
import Section from '../Shared/Section';
import Textarea from '../Shared/Textarea';

type Props = {
  splashTitle: string;
  splashSubtitle: string;
  title: string;
  introduction: string;
  formTitle: string;
  formTerms: string;
  image: any;
  imageMobile: any;
  fileUploadLabel?: string;
  isFileUploadEnabled?: boolean;
};

const AnimatedForm: FC<Props> = ({
  splashTitle,
  splashSubtitle,
  title,
  introduction,
  formTitle,
  formTerms,
  image,
  imageMobile,
  fileUploadLabel,
  isFileUploadEnabled,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const { isViewportMobile } = useViewportWidth();

  function renderImage() {
    if (isViewportMobile && imageMobile) {
      return <Image image={imageMobile} />;
    } else if (image) {
      return <Image image={image} />;
    } else {
      return null;
    }
  }

  const refSection = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const schedule = rafSchedule(() => {
      setIsFormVisible(
        window.scrollY >= refSection.current?.offsetTop - 256 ||
          window.scrollY >=
            Math.max(
              document.body.scrollHeight,
              document.body.offsetHeight,
              document.documentElement.clientHeight,
              document.documentElement.scrollHeight,
              document.documentElement.offsetHeight
            ) -
              window.innerHeight -
              256 ||
          window.innerHeight + window.pageYOffset >= document.body.offsetHeight
      );
    });

    window.addEventListener('scroll', schedule);

    return () => {
      window.removeEventListener('scroll', schedule);
    };
  }, []);

  async function onSubmit(values: any) {
    if (isFormSubmitting) {
      return;
    }

    if (values.website) {
      // Reject submission if honeypot field is populated.
      return;
    }

    setIsFormSubmitting(true);

    // 5 = General
    // 6 = Careers
    const formId = window.location.href.indexOf('/careers/') !== -1 ? 6 : 5;

    const formData = new FormData();
    formData.append('input_1', values.name);
    formData.append('input_2', values.email);
    formData.append('input_3', values.number);
    formData.append('input_4', values.message);

    if (formId === 6) {
      if (isFileUploadEnabled) {
        const fileInput = document.getElementById(
          'animated_form_file'
        ) as HTMLInputElement;

        formData.append('input_5', fileInput.files[0]);
      }
    }

    formData.append('input_6', window.location.href);

    await axios({
      method: 'post',
      url: `${process.env.GATSBY_CMS_URL}/wp-json/gf/v2/forms/${formId}/submissions`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },

      auth: {
        username: '6vtDo2FhyNaPmRh4E',
        password: 'sziK tcWC ut46 fdfz AvJm 9yzu',
      },
    });

    if (formId === 6) {
      window.location.href = '/careers/thank-you/';
    } else {
      window.location.href = '/thank-you/';
    }
  }

  return (
    <AnimatedFormSection ref={refSection} id='animated-form'>
      <StyledSVG isVisible={!isFormVisible} />
      <SplashText isVisible={!isFormVisible}>
        <Section>
          <H2 isColorInverted>{splashTitle}</H2>
          <P isColorInverted>{splashSubtitle}</P>
        </Section>
      </SplashText>
      <FormContainer isVisible={isFormVisible}>
        <Row>
          <AnimatedFormBlockText mdWidth={1 / 2}>
            {title && <H2>{title}</H2>}
            <P>{introduction}</P>
          </AnimatedFormBlockText>
          <Block mdWidth={1 / 2}>
            {formTitle && <H2>{formTitle}</H2>}
            <AnimatedFormFormContainer>
              <Form
                onSubmit={onSubmit}
                validate={(values) => {
                  const errors = {} as any;

                  if (!values.name) {
                    errors.name = 'Required';
                  }
                  if (!values.email) {
                    errors.email = 'Required';
                  }
                  if (!values.message) {
                    errors.message = 'Required';
                  }

                  return errors;
                }}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Field
                      name='name'
                      render={({ input, meta }) => (
                        <Input
                          input={input}
                          meta={meta}
                          id='animated_form_name'
                          label='Your Name'
                          type='text'
                        />
                      )}
                    />
                    <Field
                      name='email'
                      render={({ input, meta }) => (
                        <Input
                          input={input}
                          meta={meta}
                          id='animated_form_email'
                          label='Your Email'
                          type='email'
                        />
                      )}
                    />
                    <Field
                      name='number'
                      render={({ input }) => (
                        <Input
                          input={input}
                          id='animated_form_number'
                          label='Contact number'
                          type='tel'
                        />
                      )}
                    />
                    <Field
                      name='website'
                      render={({ input }) => (
                        <Input
                          input={input}
                          id='animated_form_website'
                          label='Website'
                          type='hidden'
                        />
                      )}
                    />
                    <Field
                      name='message'
                      render={({ input, meta }) => (
                        <Textarea
                          input={input}
                          meta={meta}
                          id='animated_form_message'
                          label='Message'
                        />
                      )}
                    />
                    {isFileUploadEnabled && (
                      <Field
                        name='file'
                        render={({ input, meta }) => (
                          <FileInput
                            input={input}
                            meta={meta}
                            id='animated_form_file'
                            label={fileUploadLabel}
                          />
                        )}
                      />
                    )}
                    {/* TODO: Checkbox */}
                    <Button
                      as='input'
                      type='submit'
                      value={isFormSubmitting ? 'Submitting...' : 'Submit'}
                      disabled={isFormSubmitting}
                    />
                  </form>
                )}
              />
            </AnimatedFormFormContainer>
          </Block>
        </Row>
      </FormContainer>
      <AnimatedFormImageBackground>{renderImage()}</AnimatedFormImageBackground>
    </AnimatedFormSection>
  );
};

export default AnimatedForm;

const AnimatedFormSection = styled(Section)`
  overflow: hidden;
`;

type StyledSVGProps = {
  isVisible: boolean;
};

const StyledSVG = styled(SVG)<StyledSVGProps>`
  display: none;
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: ${(p) =>
    p.isVisible
      ? 'translate(-50%, -50%) scale(1)'
      : 'translate(-50%, 800%) scale(64)'};
  transition: ${(p) =>
    p.isVisible
      ? 'opacity 0.3s 0.15s, transform 0.6s, visibility 0s'
      : 'opacity 0.3s 0.15s, transform 0.6s, visibility 0s 0.6s'};
  transition-timing-function: ${(p) =>
    p.isVisible
      ? 'cubic-bezier(0.8, 0, 0.6, 1)'
      : 'cubic-bezier(0.4, 0, 0.2, 1)'};
  visibility: ${(p) => (p.isVisible ? 'visible' : 'hidden')};
  z-index: 30;

  ${variables.breakpoints.md} {
    display: block;
  }

  @media screen and (min-width: 1921px) {
    transform: ${(p) =>
      p.isVisible
        ? 'translate(-50%, -37.5%) scale(2)'
        : 'translate(-50%, 800%) scale(64)'};
  }
`;

type SplashTextProps = {
  isVisible: boolean;
};

const SplashText = styled.div<SplashTextProps>`
  display: none;
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  text-align: center;
  transform: translateY(-40%);
  transition: ${(p) =>
    p.isVisible
      ? 'opacity 0.3s 0.45s, visibility 0s'
      : 'opacity 0.3s, visibility 0s 0.3s'};
  visibility: ${(p) => (p.isVisible ? 'visible' : 'hidden')};
  z-index: 40;

  ${variables.breakpoints.md} {
    display: block;
  }
`;

const AnimatedFormBlockText = styled(Block)`
  margin-bottom: 4rem;
`;

type FormContainerProps = {
  isVisible: boolean;
};

const FormContainer = styled.div<FormContainerProps>`
  position: relative;
  z-index: 20;

  ${variables.breakpoints.md} {
    opacity: ${(p) => (p.isVisible ? 1 : 0)};
    transform: ${(p) => (p.isVisible ? 'translateY(0)' : 'translateY(2rem)')};
    transition: ${(p) =>
      p.isVisible
        ? 'opacity 0.3s 0.3s, transform 0.3s 0.3s, visibility 0s'
        : 'opacity 0.3s 0.15s, transform 0.3s 0.15s, visibility 0s 0.45s'};
    visibility: ${(p) => (p.isVisible ? 'visible' : 'hidden')};
  }

  ${variables.breakpoints.xxxl} {
    & > ${Row} {
      margin-left: auto;
      margin-right: auto;
      max-width: 1920px;
    }
  }
`;

type FormWrapperProps = {
  isVisible: boolean;
};

const AnimatedFormFormContainer = styled.div`
  position: relative;
`;

const FormWrapper = styled.div<FormWrapperProps>`
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
  transition: ${(p) =>
    p.isVisible
      ? 'opacity 0.3s, visibility 0s 0s'
      : 'opacity 0.3s, visibility 0s 0.3s'};
  visibility: ${(p) => (p.isVisible ? 'visible' : 'hidden')};
`;

type MessageContainerProps = {
  isVisible: boolean;
};

const MessageContainer = styled.div<MessageContainerProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
  transition: ${(p) =>
    p.isVisible
      ? 'opacity 0.3s, visibility 0s 0s'
      : 'opacity 0.3s, visibility 0s 0.3s'};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  visibility: ${(p) => (p.isVisible ? 'visible' : 'hidden')};
`;

const AnimatedFormImageBackground = styled(ImageBackground)`
  filter: brightness(50%);
`;
