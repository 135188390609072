import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import escapeSpecialCharacters from '../../helpers/escapeSpecialCharacters';
import variables from '../../helpers/variables';
import Block from '../Shared/Block';
import BlockButton from '../Shared/BlockButton';
import Button from '../Shared/Button';
import H3 from '../Shared/H3';
import P from '../Shared/P';
import Row from '../Shared/Row';
import RowButtons from '../Shared/RowButtons';
import Section from '../Shared/Section';
import SectionWhite from '../Shared/SectionWhite';

type Props = {
  ctas?: any[];
  columns: number;
  isColorInverted: boolean | null;
};

const Ctas: FC<Props> = ({ columns, ctas, isColorInverted }) => {
  let mdWidth = undefined;
  let lgWidth = undefined;

  switch (columns) {
    case 1:
      break;
    case 2:
      mdWidth = 1 / 2;
      break;
    case 3:
      mdWidth = 1 / 3;
      break;
    case 4:
      mdWidth = 1 / 2;
      lgWidth = 1 / 4;
      break;
    default:
      // Fail silently.
      break;
  }

  const BlockButtonJSX = ({ link }) => {
    return (
      <BlockButton>
        {link.url.includes('http') ? (
          <Button
            as='a'
            href={link.url}
            isColorInverted={isColorInverted !== null}
          >
            {escapeSpecialCharacters(link.title)}
          </Button>
        ) : (
          <Button
            as={Link}
            to={link.url}
            isColorInverted={isColorInverted !== null}
          >
            {escapeSpecialCharacters(link.title)}
          </Button>
        )}
      </BlockButton>
    );
  };

  const SectionInnerJSX = (
    <CtasRow>
      {ctas?.map((item, index) => (
        <CtasBlock key={index} mdWidth={mdWidth} lgWidth={lgWidth}>
          <H3 isColorInverted={isColorInverted !== null}>{item.title}</H3>
          <CtasP isColorInverted={isColorInverted !== null}>
            {item.description}
          </CtasP>
          {(item.link || item.linkTwo || item.linkThree || item.linkFour) && (
            <RowButtons>
              {item.link && <BlockButtonJSX link={item.link} />}
              {item.linkTwo && <BlockButtonJSX link={item.linkTwo} />}
              {item.linkThree && <BlockButtonJSX link={item.linkThree} />}
              {item.linkFour && <BlockButtonJSX link={item.linkFour} />}
            </RowButtons>
          )}
        </CtasBlock>
      ))}
    </CtasRow>
  );

  return isColorInverted !== null ? (
    <SectionWhite>{SectionInnerJSX}</SectionWhite>
  ) : (
    <Section>{SectionInnerJSX}</Section>
  );
};

export default Ctas;

const CtasRow = styled(Row)`
  margin-bottom: -4rem;
`;

const CtasBlock = styled(Block)`
  margin-bottom: 4rem;
`;

const CtasP = styled(P)`
  margin-bottom: 1.5rem;

  ${variables.breakpoints.lg} {
    margin-bottom: 1.875rem;
  }
`;
