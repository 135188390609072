import { Link } from 'gatsby';
import React, { FC } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import variables from '../../helpers/variables';
import Block from '../Shared/Block';
import Button from '../Shared/Button';
import H2 from '../Shared/H2';
import Image from '../Shared/Image';
import Row from '../Shared/Row';
import Section from '../Shared/Section';
import StyledSlider from '../Shared/StyledSlider';
import Subtitle from '../Shared/Subtitle';
import VisuallyHidden from '../Shared/VisuallyHidden';

type Props = {
  caseStudies?: any[];
};

const CaseStudySlider: FC<Props> = ({ caseStudies }) => {
  return (
    <Section>
      <Row>
        <Block>
          <StyledSlider.StyledSlider arrows={false} dots>
            {caseStudies?.map(
              (item, index) =>
                item.caseStudy !== null && (
                  <StyledSlider.Slide key={index}>
                    <StyledSlider.SlideImage>
                      <Image
                        image={
                          item.caseStudy.hero_case_study.heroCaseStudy.image
                        }
                      />
                    </StyledSlider.SlideImage>
                    <StyledSlider.SlideText>
                      <Subtitle fontSize={variables.css.fontSize.md}>
                        Previous mission
                      </Subtitle>
                      <H2>{item.caseStudy.title}</H2>
                      <Button as={Link} to={item.caseStudy.uri}>
                        Read more
                        <VisuallyHidden> about this case study</VisuallyHidden>
                      </Button>
                    </StyledSlider.SlideText>
                  </StyledSlider.Slide>
                )
            )}
          </StyledSlider.StyledSlider>
        </Block>
      </Row>
    </Section>
  );
};

export default CaseStudySlider;
